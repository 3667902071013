import React from 'react';
import {
  useTheme, Container, Grid, Box,
  Typography, Stack, useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import DoorToDoorIcon from '../../images/DoorToDoorIcon.png';
import Causeway from '../../images/causeway.jpeg';
import SingaporeMBS from '../../images/Singapore-MBS.jpeg';
import MalaysiaKL from '../../images/Malaysia-KL.webp';
import Cambodia from '../../images/Cambodia.jpg';
import Batam from '../../images/batam.jpeg';

const CoreServices = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  return (
    <div style={{ backgroundColor: '#E5FCF1' }}>
      <Container
        maxWidth='lg'
        sx={{ backgroundColor: '#E5FCF1', padding: 0 }}
      >
        <Grid
          container
          spacing={0}
          maxWidth={'lg'}
        >
          <ImageGridContainer
            image={SingaporeMBS}
            text='China - Singapore'
          />
          <Grid
            item
            xs={12}
            md={6}
            sx={{
             // width: '500px',
              backgroundColor: '#FFF',
            }}
          >
            <CoreServiceDescription
              lcl
              fcl
              airFreight
              origin={t('landingPage.sectionCoreService.china')}
              destination={t('landingPage.sectionCoreService.singapore')}
              content={
                <>
                  <Typography
                    variant='body1'
                  >
                    {t('landingPage.sectionCoreService.sg.lineOne')}
                  </Typography>
                  <br />
                  <Typography
                    variant='body1'
                  >
                    {t('landingPage.sectionCoreService.sg.lineTwo')}
                  </Typography>
                </>
              }
              af
              sf
              gf={false}
              dtd
            />
          </Grid>
          {
            isMobileView ? (
              <ImageGridContainer image={MalaysiaKL} text='China - Malaysia' />
            ) : null
          }
          <Grid
            item
            xs={12}
            md={6}
            sx={{
             // width: '500px',
              height: '500px',
              // backgroundColor: '#FFF',
            }}
          >
            <Box
              // width='500px'
              height='500px'
              sx={{
                position: 'relative',
                backgroundColor: '#FFF',
              }}
            >
              <CoreServiceDescription
                lcl
                fcl
                airFreight
                origin={t('landingPage.sectionCoreService.china')}
                destination={t('landingPage.sectionCoreService.malaysia')}
                content={
                  <>
                    <Typography
                      variant='body1'
                    >
                      {t('landingPage.sectionCoreService.my.lineOne')}
                    </Typography>
                    <br />
                    <Typography
                      variant='body1'
                    >
                      {t('landingPage.sectionCoreService.my.lineTwo')}
                    </Typography>
                  </>
                }
                af
                sf
                gf={false}
                dtd
              />
            </Box>
          </Grid>
          {
            !isMobileView ? (
              <ImageGridContainer image={MalaysiaKL} text='China - Malaysia' />
            ) : null
          }
          <ImageGridContainer image={Batam} text='China - Batam' />
          <Grid
            item
            xs={12}
            md={6}
            sx={{
             // width: '500px',
              height: '500px',
              backgroundColor: '#FFF',
            }}
          >
            <Box
              // width='500px'
              height='500px'
              sx={{
                position: 'relative',
                backgroundColor: '#FFF',
              }}
            >
              <CoreServiceDescription
                lcl
                fcl
                airFreight={false}
                origin={t('landingPage.sectionCoreService.china')}
                destination={t('landingPage.sectionCoreService.batam')}
                content={
                  <>
                    <Typography
                      variant='body1'
                    >
                      {t('landingPage.sectionCoreService.bm.lineOne')}
                    </Typography>
                    <br />
                    <Typography
                      variant='body1'
                    >
                      {t('landingPage.sectionCoreService.bm.lineTwo')}
                    </Typography>
                  </>
                }
                af={false}
                sf
                gf={false}
                dtd
              />
            </Box>
          </Grid>
          {
            isMobileView ? (
              <ImageGridContainer image={Causeway} text='Malaysia - Singapore' />
            ) : null
          }
          <Grid
            item
            xs={12}
            md={6}
            sx={{
             // width: '500px',
              backgroundColor: '#FFF',
            }}
          >
            <CoreServiceDescription
              lcl
              fcl
              airFreight={false}
              origin={t('landingPage.sectionCoreService.malaysia')}
              destination={t('landingPage.sectionCoreService.singapore')}
              content={
                <>
                  <Typography
                    variant='body1'
                  >
                    {t('landingPage.sectionCoreService.mysg.lineOne')}
                  </Typography>
                  <br />
                  <Typography
                    variant='body1'
                  >
                    {t('landingPage.sectionCoreService.mysg.lineTwo')}
                  </Typography>
                </>
              }
              af
              sf
              gf={false}
              dtd
            />
          </Grid>
          {
            !isMobileView ? (
              <ImageGridContainer image={Causeway} text='Malaysia - Singapore' />
            ) : null
          }
          <ImageGridContainer image={Cambodia} text='Singapore - Cambodia' />
          <Grid
            item
            xs={12}
            md={6}
            sx={{
             // width: '500px',
              height: '500px',
              backgroundColor: '#FFF',
            }}
          >
            <Box
              // width='500px'
              height='500px'
              sx={{
                position: 'relative',
                backgroundColor: '#FFF',
              }}
            >
              <CoreServiceDescription
                lcl
                fcl
                airFreight
                origin={t('landingPage.sectionCoreService.singapore')}
                destination={t('landingPage.sectionCoreService.cambodia')}
                content={
                  <>
                    <Typography
                      variant='body1'
                    >
                      {t('landingPage.sectionCoreService.camsg.lineOne')}
                    </Typography>
                    <br />
                    <Typography
                      variant='body1'
                    >
                      {t('landingPage.sectionCoreService.camsg.lineTwo')}
                    </Typography>
                  </>
                }
                af
                sf
                gf={false}
                dtd
              />
            </Box>
          </Grid>
          {/* <Grid xs={12} md={6} sx={{ backgroundColor: 'red' }}>e</Grid>
          <Grid xs={12} md={6}>f</Grid> */}
        </Grid>
      </Container>
    </div>
  );
};

export default CoreServices;

interface CoreServiceDescriptionProps extends RenderSvcProps, WhereToWhereProps, ModeOfTransportProps {
  content: JSX.Element;
}

const CoreServiceDescription: React.FC<CoreServiceDescriptionProps> = ({
  lcl,
  fcl,
  airFreight,
  origin,
  destination,
  content,
  af,
  sf,
  gf,
  dtd,
}) => {
  return (
    <Box
      width='100%'
      height='500px'
      sx={{
        position: 'relative',
        // backgroundColor: 'red',
      }}
      p={2}
    >
      <RenderSvc
        lcl={lcl}
        fcl={fcl}
        airFreight={airFreight}
      />
      <WhereToWhere
        origin={origin}
        destination={destination}
      />
      {content}
      <ModeOfTransport
        af={af}
        sf={sf}
        gf={gf}
        dtd={dtd}
      />
    </Box>
  );
};

const AirFreightIcon: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box textAlign='center'>
      <AirplanemodeActiveIcon fontSize='large' color='primary' />
      <Typography variant='body1'>{t('landingPage.sectionCoreService.airFreight')}</Typography>
    </Box>
  );
};

const SeaFreightIcon: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box textAlign='center'>
      <DirectionsBoatIcon fontSize='large' color='primary' />
      <Typography variant='body1'>{t('landingPage.sectionCoreService.seaFreight')}</Typography>
    </Box>
  );
};

const LandFreightIcon: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box textAlign='center'>
      <LocalShippingIcon fontSize='large' color='primary' />
      <Typography variant='body1'>{t('landingPage.sectionCoreService.groundFreight')}</Typography>
    </Box>
  );
};

const DoorToDoor: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box textAlign='center'>
      {/* <LocalShippingIcon fontSize='large' color='primary' /> */}
      <img
        src={DoorToDoorIcon}
        alt='DoorToDoorServiceIcon'
        height='30px'
        style={{
          marginTop: '5px'
        }}
      />
      <Typography variant='body1'>{t('landingPage.sectionCoreService.doorToDoor')}</Typography>
    </Box>
  );
};

interface ModeOfTransportProps {
  af: boolean;
  sf: boolean;
  gf: boolean;
  dtd: boolean;
}

const ModeOfTransport: React.FC<ModeOfTransportProps> = ({
  af,
  sf,
  gf,
  dtd,
}) => {
  return (
    <Stack
      direction='row'
      spacing={3}
      alignItems='flex-end'
      // justifyContent='space-between'
      pt={2}
    >
      {
        af ? (
          <AirFreightIcon />
        ) : null
      }
      {
        sf ? (
          <SeaFreightIcon />
        ) : null
      }
      {
        gf ? (
          <LandFreightIcon />
        ) : null
      }
      {
        dtd ? (
          <DoorToDoor />
        ) : null
      }
    </Stack>
  );
};

interface WhereToWhereProps {
  origin: string;
  destination: string;
}

const WhereToWhere: React.FC<WhereToWhereProps> = ({
  origin,
  destination,
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
  <Stack direction='row' alignItems='center' spacing={1} pt={2} pb={2}>
      <Typography
        variant={isMobileView ? 'h5' : 'h3'}
        fontWeight='bold'
        fontStyle='italic'
      >
        {origin}
      </Typography>
      <SwapHorizIcon
        fontSize='large'
        color='secondary'
        sx={{
          fontSize: '50px'
        }}
      />
      <Typography
        variant={isMobileView ? 'h5' : 'h3'}
        fontWeight='bold'
        fontStyle='italic'
      >
        {destination}
      </Typography>
    </Stack>
  );
};

interface RenderSvcProps {
  lcl: boolean;
  fcl: boolean;
  airFreight: boolean;
}

const RenderSvc: React.FC<RenderSvcProps> = ({
  lcl,
  fcl,
  airFreight,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const item = (text: string) => (
    <Typography
      variant='body1'
      color={theme.palette.secondary.main}
      // backgroundColor={theme.palette.primary.main}
      p={0.5}
      // m={0.5}
      pt={0}
      pb={0}
      sx={{
        backgroundColor: theme.palette.primary.main,
      }}
    >
      {text}
    </Typography>
  );
  const airFreightText = t('landingPage.sectionCoreService.airFreight').toUpperCase();
  return (
    <Stack direction='row' gap={2}>
      {
        lcl ? (
          item('LCL')
        ) : null
      }
      {
        fcl ? (
          item('FCL')
        ) : null
      }
      {
        airFreight ? (
          item(airFreightText)
        ) : null
      }
    </Stack>
  );
};

interface ImageGridContainerProps {
  image: any;
  text: string;
}

const ImageGridContainer: React.FC<ImageGridContainerProps> = ({
  image,
  text,
}) => {
  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        // width: '500px',
        height: '500px'
      }}
    >
      <Box
        width='100%'
        height='100%'
        sx={{
          position: 'relative',
          transition: 'border 0.6s ease',
            '&:hover img': {
            border: '4px solid orange', // Orange border on image hover
          },
        }}
      >
        <img
          src={image}
          alt="teampic"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Translucent overlay color
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            textAlign: 'center',
            padding: '20px',
            opacity: 0, // Initially transparent
            transition: 'opacity 0.6s ease', // Smooth opacity transition on hover
            '&:hover': {
              opacity: 1, // Fully visible on hover
            },
          }}
        >
          <Typography variant='h5' fontWeight='bold' color='#FFF'>"{text}"</Typography>
          {/* You can add more text content or other elements inside the Box */}
        </Box>
      </Box>
    </Grid>
  );
};
