import React, { useState } from 'react';
import {
  useTheme, Button, Grid, TextField,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Stack, Typography, styled, Box,
} from '@mui/material';
import { Place, LocalPhone, Email, CopyAll } from '@mui/icons-material';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import SectionTitle from './SectionTitle';
import FormTextField from '../../../components/utility/forms/FormTextField';
import AlertDialog, { AlertDialogInterface } from './AlertDialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';

export interface ContactUsDialogInt {
  isOpen: boolean;
}

interface ContactUsDialogInterface {
  contactUsDialog: ContactUsDialogInt;
  setContactUsDialog: React.Dispatch<React.SetStateAction<ContactUsDialogInt>>
}

const ContactUsDialog: React.FC<ContactUsDialogInterface> = ({
  contactUsDialog,
  setContactUsDialog,
}) => {
  const theme = useTheme();
  const { isOpen } = contactUsDialog;
  const { t } = useTranslation();
  const [error, setError] = useState<undefined | string>(undefined);
  const [copyMessage, setCopyMessage] = useState<string>(t('contactUs.copy'));
  const [altertDialog, setAlertDialog] = useState<AlertDialogInterface>({
    isOpen: false,
    icon: undefined,
    title: '',
    message: '',
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // Add your logic here to handle form submission
    try {
      console.log(formData);
      // Reset the form
      await axios.post("http://localhost:3030/message", formData);
    } catch (error) {
      setError('Something went wrong. Please try again or call us at +65 6887 9700');
      console.log('error: ', error);
    }
  };

  const handleClose = () => {
    setContactUsDialog({
      isOpen: false,
    });
    setCopyMessage(t('contactUs.copy'));
    setError(undefined);
  };

  const handleCopyButton = (buttonName: string) => {
    setCopyMessage(`${buttonName} has been copied`);
    if (buttonName === 'Address') {
      navigator.clipboard.writeText('200 Cantonment Road, Southpoint, #10-02, Singaopore 089763');
    } else if (buttonName === 'Email') {
      navigator.clipboard.writeText('200 Cantonment Road, Southpoint, #10-02, Singaopore 089763');
    } else if (buttonName === 'Phone Number') {
      navigator.clipboard.writeText('+6568879700')
    }
  };

  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email().required('Email is required'),
    message: Yup.string().required('Message is required'),
  });

  return (
    <>
      <AlertDialog
        alertDialog={altertDialog}
        setAlertDialog={setAlertDialog}
      />
      <Formik
        validationSchema={schema}
        initialValues={{
          name: '',
          email: '',
          message: '',
        }}
        onSubmit={async (values, actions) => {
          try {
            actions.setSubmitting(true);
            await axios.post("https://wsrvjnclogistics.yapfengwei.com/message", values);
            setAlertDialog({
              isOpen: true,
              icon: <CheckCircleIcon sx={{ color: 'green' }} />,
              title: t('contactUs.resDialog.success.title'),
              message: t('contactUs.resDialog.success.message'),
            });
            handleClose();
            actions.resetForm();
          } catch (error) {
            setAlertDialog({
              isOpen: true,
              icon: <WarningIcon sx={{ color: 'red' }} />,
              title: t('contactUs.resDialog.failure.title'),
              message: t('contactUs.resDialog.failure.message'),
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          errors,
          dirty,
          submitForm,
        }) => (
          <Form>
            <Dialog open={isOpen} onClose={handleClose} fullWidth>
              <DialogTitle sx={{ fontWeight: 'bold', }}>{t('contactUs.title')}</DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={1}
                  justifyContent='center'
                // sx={{
                //   marginBottom: theme.spacing(1),
                // }}
                >
                  <Grid item xs={12} md={12}>
                    <Button
                      startIcon={<Place />}
                      variant="contained"
                      fullWidth
                      sx={{
                        height: '100%',
                        fontSize: '10px'
                      }}
                      onClick={() => handleCopyButton('Address')}
                    >
                      200 Cantonment Road, Southpoint, #10-02, Singaopore 089763
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      startIcon={<LocalPhone />}
                      variant="contained"
                      fullWidth
                      sx={{
                        height: '100%',
                        fontSize: '10px'
                      }}
                      onClick={() => handleCopyButton('Phone Number')}
                    >
                      +65 91145329 (Main)
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      startIcon={<Email />}
                      variant="contained"
                      fullWidth
                      sx={{
                        height: '100%',
                        fontSize: '10px'
                      }}
                      onClick={() => handleCopyButton('Email')}
                    >
                      shulan@jnclogistics.com.sg
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      gap={1}
                      sx={{
                        // width: '100%',
                        // textAlign: 'center',
                        marginTop: theme.spacing(1),
                      }}>
                      <CopyAll fontSize='small' />
                      <HeartbeatText variant="caption" align="center">
                        {copyMessage}
                      </HeartbeatText>
                    </Stack>
                  </Grid>
                </Grid>
                <SectionTitle
                  title={t('contactUs.subTitle')}
                />
                <Box style={{ paddingTop: theme.spacing(1) }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={FormTextField}
                        name='name'
                        label={t('contactUs.form.name')}
                        type='text'
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={FormTextField}
                        name='email'
                        label={t('contactUs.form.email')}
                        type='email'
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={FormTextField}
                        name='message'
                        label={t('contactUs.form.message')}
                        type='text'
                        required
                        multiline
                        rows={3}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant='outlined'>{t('contactUs.form.cancel')}</Button>
                <Button
                  onClick={submitForm}
                  variant='contained'
                  disabled={isSubmitting || !dirty || (Object.keys(errors).length > 0)}
                >
                  {t('contactUs.form.submit')}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ContactUsDialog;

const HeartbeatText = styled(Typography)({
  animation: '$heartbeat 1s infinite',
  '@keyframes heartbeat': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
});
