import React, { useState, useEffect } from 'react';
import {
  styled, useTheme, Typography, Container,
  Grid, Box,
  // Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import {
  Store, DirectionsBoat, Flight, PropaneTank,
  SupportAgent, AcUnit, Widgets, Business,
  ShoppingBag,
  //Flexibag, container
} from '@mui/icons-material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import ServiceDialog, { ServiceDialogInterface, ServiceDialogProps } from './ServiceDialog';
import Bagimg from '../../images/WarehouseAboutUS.jpg';
import { useTranslation } from 'react-i18next';

const RootContainer = styled('div')({
  position: 'relative',
  height: '40vh',
  backgroundImage: `url(${Bagimg})`, // Replace with the path to your image
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
});

const DarkOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.55)', // Adjust the opacity and color as needed
});

const Content = styled('div')({
  position: 'absolute',
  bottom: '5%',
  paddingLeft: '3%',
  // right: '10%',
  // transform: 'translate(-50%, -50%)',
  textAlign: 'left',
  color: '#fff',
});

const ServicesPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <>
      <RootContainer>
        <DarkOverlay></DarkOverlay>
        <Content>
          <>
            <Typography variant="h4" fontWeight='bold' color='secondary'>OUR SERVICES </Typography>
            {/* <Typography variant="h2" fontWeight='bold' display='inline' color='secondary'>OUR SERVICES </Typography> */}
            {/* <Typography variant="h2" fontWeight='bold' display='inline' color='#FFF'>LOGISTICS</Typography> */}
          </>
          {/* <>
            <Typography variant="body1" fontWeight='bold' color='#FFF'>Your text content goes here. Talk about how great your company is.</Typography>
          </> */}
        </Content>
      </RootContainer>
      <ServicesList />
    </>
  );
};

export default ServicesPage;

export const servicesList = [
  {
    id: 'airFreight',
    icon: Flight,
    title: 'Air Freight',
    description: 'JNC Air Freight Caters to both local and overseas clients',
    image: 'https://static.wixstatic.com/media/23e543_97180aeb5f0e4061bd8e580e08ed981c~mv2.jpg/v1/fill/w_512,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_97180aeb5f0e4061bd8e580e08ed981c~mv2.jpg'
  },
  {
    id: 'coldChain',
    icon: AcUnit,
    title: 'Cold Chain',
    description: 'A one-stop service centre, for all food chain',
    image: 'https://static.wixstatic.com/media/23e543_bea5eb0f05544e649ab5aafabbb067d5~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_bea5eb0f05544e649ab5aafabbb067d5~mv2.jpg'
  },
  {
    id: 'eCommerce',
    icon: Store,
    title: 'E-Commerce',
    description: 'JNC Air Freight Caters to both local and overseas clients.',
    image: 'https://static.wixstatic.com/media/b759c6_0c6f6f2d8080424ba177b2f15bfeb2e0~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/b759c6_0c6f6f2d8080424ba177b2f15bfeb2e0~mv2.jpg'
  },
  {
    id: 'flexiBag',
    icon: ShoppingBag,
    title: 'Flexi-Bag',
    description: 'Flexi-bags (also called flexi-tanks) offer an alternative to ISO tank containers',
    image: 'https://static.wixstatic.com/media/bb6119_1212d68ef4db45428a36525d4b31f22c~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/bb6119_1212d68ef4db45428a36525d4b31f22c~mv2.jpg'
  },
  {
    id: 'isoTank',
    icon: PropaneTank,
    title: 'ISO tank',
    description: 'Has more than 500 own ISO Tank',
    image: 'https://static.wixstatic.com/media/23e543_94d7458e926049879b76ed44edf65080~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_94d7458e926049879b76ed44edf65080~mv2.jpg'
  },
  {
    id: 'lclConsolidation',
    icon: Widgets,
    title: 'LCL Consolidation',
    description: 'LCL Consolidation to more than 50 ports destinations',
    image: 'https://static.wixstatic.com/media/4715bd_40fb51231f884843836857eb63cd754a~mv2.jpeg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/4715bd_40fb51231f884843836857eb63cd754a~mv2.jpeg'
  },
  {
    id: 'projectCargos',
    icon: Business,
    title: 'Project Cargos',
    description: 'For larger cargo transportation, JNC offers shipping for all categories',
    image: 'https://static.wixstatic.com/media/23e543_e3e226f828994283b48b2c63713aa5fd~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_e3e226f828994283b48b2c63713aa5fd~mv2.jpg'
  },
  {
    id: 'socOperation',
    icon: DirectionsBoat,
    title: 'SOC Operation',
    description: 'More than 1000 own containers',
    image: 'https://static.wixstatic.com/media/bb6119_92e752ac552e470eaa83732ecc9bfe7f~mv2.png/v1/fill/w_396,h_340,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/bb6119_92e752ac552e470eaa83732ecc9bfe7f~mv2.png'
  },
  {
    id: 'shipAgencies',
    icon: SupportAgent,
    title: 'Ship Agencies',
    description: 'As booking agent for APS',
    image: 'https://static.wixstatic.com/media/23e543_bfc6940e019d4f1ea8388d8b4804a6fa~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_bfc6940e019d4f1ea8388d8b4804a6fa~mv2.jpg'
  },
  {
    id: 'specialProjectCargo',
    icon: Business,
    title: 'Special Project Cargo',
    description: 'We specialise in the handling and storage for your special cargo',
    image: 'https://static.wixstatic.com/media/23e543_1b56d0f2555046c6b223beb7a5973b5f~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_1b56d0f2555046c6b223beb7a5973b5f~mv2.jpg'
  },
];

const ServicesList = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [serviceDialog, setServiceDialog] = useState<ServiceDialogInterface>({
    isOpen: false,
    title: undefined,
    description: undefined,
    image: undefined,
  });

  return (
    <Container
      sx={{
        paddingTop: theme.spacing(4),
        // textAlign: 'center'
      }}
    >
      <Grid container spacing={3}>
        {
          servicesList.map((service) => (
            <ServiceItem
              key={`svcItem-${service.title}`}
              ServiceIcon={service.icon}
              title={t(`services.${service.id}.title`)}
              shortDescription={t(`services.${service.id}.shortDescription`)}
              description={t(`services.${service.id}.description`)}
              image={service.image}
              setServiceDialog={setServiceDialog}
            />
          ))
        }
      </Grid>
      {/* {
        servicesList.map((service) => (
          <ServiceListItem
            title={service.title}
            description={service.description}
            image={service.image}
          />
        ))
      } */}
      <ServiceDialog
        serviceDialog={serviceDialog}
        setServiceDialog={setServiceDialog}
      />
    </Container>
  );
};

interface ServiceListItemProps {
  ServiceIcon: any;
  title: string;
  shortDescription: string;
  description: string;
  image: string;
  setServiceDialog: React.Dispatch<React.SetStateAction<ServiceDialogInterface>>;
}

const ServiceItem: React.FC<ServiceListItemProps> = ({
  ServiceIcon,
  title,
  shortDescription,
  description,
  image,
  setServiceDialog,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Grid
      item
      xs={12}
      md={4}
      sx={{
        cursor: 'pointer',
        padding: theme.spacing(1),
        // backgroundColor: 'red'
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        setServiceDialog({
          isOpen: true,
          title,
          description,
          image,
        });
      }}
    >
      <ServiceIcon
        sx={{
          width: '100px',
          height: '100px',
          margin: 'auto',
          color: '#FFF',
          backgroundColor: isHovered ? theme.palette.secondary.main : theme.palette.primary.dark,
          padding: theme.spacing(2),
          transition: 'background-color 0.5s ease'
        }}
      />
      <Typography
        variant='h5'
        paddingTop={theme.spacing(2)}
        color={isHovered ? 'secondary' : ''}
        sx={{
          transition: 'color 0.5s ease'
        }}
      >
        {title}
      </Typography>
      <Typography
        variant='body1'
        paddingTop={theme.spacing(2)}
        paddingBottom={theme.spacing(2)}
      >
        {shortDescription}
      </Typography>
    </Grid>
  );
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface ServiceListItemInterface {
  title: string;
  description: string;
  image: string;
}

const ServiceListItem: React.FC<ServiceListItemInterface> = ({
  title,
  description,
  image,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleChange = () => {
    setExpanded((prevState) => !prevState);
  };
  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        // aria-controls="panel1bh-content"
        // id="panel1bh-header"
      >
        <Typography sx={{ width: '100%', flexShrink: 0 }}>
          {title}
        </Typography>
        {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item xs={12} md={7}>
            <Typography variant='body1'>{description}</Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box sx={{ textAlign: {md: 'right', xs: 'left'} }}>
              <img
                src={image}
                alt={`${title}-imgage`}
                style={{ width: '70%' }}
              />
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
