import React from 'react';
import { useTheme, Typography, Divider } from '@mui/material';

interface SectionTitleInterface {
  title: String;
}

const SectionTitle: React.FC<SectionTitleInterface> = ({
  title,
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
        alignContent: 'center',
        padding: '20px 0'
      }}
    >
      <Typography variant='h5' p={1} color='primary'>{title}</Typography>
      <Divider
        sx={{
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.main,
          width: '10%',
          minWidth: '30px',
          height: '5px',
          textAlign: 'center',
          margin: 'auto'
        }}
      />
    </div>
  );
};

export default SectionTitle;
