import { TextField, TextFieldProps } from 'formik-mui';

interface FormTextFieldInterface extends TextFieldProps {
  required: boolean;
}

const FormTextField = (props: FormTextFieldInterface) => {
  const { required, ...rest } = props;
  const label = required ? `${props.label} *` : props.label;
  return (
    <TextField
      label={label}
      fullWidth
      {...rest}
    />
  );
};

export default FormTextField;
