// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import {
  // Container, Card, CardActions, CardMedia, Button, styled, Divider,
  useTheme, Box, Typography, Grid,
  CircularProgressProps, CircularProgress, Button,
  useMediaQuery, Container, Slide, Collapse,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useLottie } from 'lottie-react';
// import Lottie from 'lottie-react';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
// import Carousel from 'react-material-ui-carousel'
import { keyframes } from '@emotion/react';

import OurServicesV2 from './OurServicesV2';
import AboutUsSectionV2 from './AboutUsSectionV2';
import KeyFeaturesSection from './KeyFeaturesSection';
import WorldToChinaSection from './WorldToChinaSection';
import FocusOnWhatMattersSection from './FocusOnWhatMattersSection';
import AboutUsSection from './AboutUsSection';
import NewsSection from './NewsSection';
import CoreServices from './CoreServices';
import OperatingInCountries from './OperatingInCountries';
import ContactUsDialog, { ContactUsDialogInt } from './Components/ContactUsDialog';
import WarehouseAnimation from "../../images/SupplyChain.json";
import MapSliderAnimation from "../../images/mapslider.json";

import BlueBoat from '../../images/BlueBoat.png';
import BlueSea from '../../images/BlueSeaTwo.jpg';
import SalesTeam from '../../images/SalesTeam.jpg';
import JNCPhoneUi from '../../images/JNCPhoneUI.png';
import JNCPhoneUiCn from '../../images/PhoneUI_cn.png';
import JNCPhoneUiEn from '../../images/PhoneUI_en.png';

interface VideoSectionProps {
  children: React.ReactNode;
}

const VideoSection: React.FC<VideoSectionProps> = ({ children }) => (
  <section
    style={{
      height: '105vh',
      position: 'relative',
      overflow: 'hidden',
    }}
  >
    {children}
  </section>
);

const Video = () => (
  <video
    style={{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    }}
    autoPlay
    muted
    loop
  >
    <source src="https://video.wixstatic.com/video/b759c6_b664192f9cc84c52adb5d8a935a2c2e5/1080p/mp4/file.mp4" type="video/mp4" />
    {/* Add alternative video formats here */}
  </video>
);

const Text = ({ overlayOpacity }) => {
  // useEffect(() => {
  //   console.log('overlayOpacity: ', overlayOpacity);
  // }, [overlayOpacity])
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 76,
        left: 16,
        color: '#fff',
        // backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '8px 16px',
        width: '100%',
      }}
      ref={ref}
    >
      <Slide direction='right' in={inView} mountOnEnter unmountOnExit timeout={1500}>
        <Typography
          variant='h1'
          fontWeight='bold'
          sx={{
            // color: overlayOpacity === 1 ? '#FFF' : '',
            // transition: 'color 0.2s',
            fontSize: {
              // xs: '3rem',
            },
          }}
        >
          {t('landingPage.sectionOne.lineOne')}
        </Typography>
      </Slide>
      <Slide direction='right' in={inView} mountOnEnter unmountOnExit timeout={1500}>
        <Typography variant='h1' fontWeight='bold' color='secondary'>{t('landingPage.sectionOne.lineTwo')}</Typography>
      </Slide>
      <Slide direction='right' in={inView} mountOnEnter unmountOnExit timeout={1500}>
        <Typography
          variant='h5'
          sx={{
            color: '#grey'
            // color: overlayOpacity === 1 ? '#FFF' : 'grey',
            // transition: 'color 0.2s',
          }}
        >
          {t('landingPage.sectionOne.lineThree')}
        </Typography>
      </Slide>
    </div>
  );
};

const TopSectionMobileTextContent = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Container maxWidth='xl' sx={{ padding: theme.spacing(2), backgroundColor: '#E5FCF1' }}>
      <Typography
        variant='h5'
        fontWeight='bold'
        textAlign='center'
        // backgroundColor='white'
      >
       {t('landingPage.sectionOne.lineOne')}
       {t('landingPage.sectionOne.lineTwo')}
      </Typography>
      <Box textAlign='center'>
      <Typography
        variant='h6'
        fontWeight='bold'
        color={theme.palette.secondary.main}
        backgroundColor={theme.palette.primary.main}
        p={0.5}
        m={0.5}
        display='inline'
      >
        FCL
      </Typography>
      <Typography
        variant='h6'
        fontWeight='bold'
        color={theme.palette.secondary.main}
        backgroundColor={theme.palette.primary.main}
        p={0.5}
        m={0.5}
        display='inline'
      >
        LCL
      </Typography>
      <Typography
        variant='h6'
        fontWeight='bold'
        color={theme.palette.secondary.main}
        backgroundColor={theme.palette.primary.main}
        p={0.5}
        m={0.5}
        display='inline'
      >
        {t('landingPage.sectionOne.lineThree')}
      </Typography>
      </Box>
    </Container>
  );
};

const TopSectionTextContent = () => {
  const theme = useTheme();
  const { lang } = useParams();
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          display: 'block',
          position: 'absolute',
          top: '100px',
          left: '500px',
        }}
      >
        <Typography
          variant='h1'
          fontWeight='bold'
          sx={{
            display: 'block',
            position: 'absolute',
          }}
          // backgroundColor='white'
        >
          {t('landingPage.sectionOne.lineOne')}
          {t('landingPage.sectionOne.lineTwo')}
        </Typography>
      </div>
      <div
        style={{
          display: 'block',
          position: 'absolute',
          top: lang === 'en' ? '330px' : '730px',
          left: '500px',
        }}
      >
        <Typography
          variant='h5'
          fontWeight='bold'
          sx={{
            // display: 'block',
            // position: 'absolute',
            // top: '330px',
            // bottom: '0px',
            // left: '500px',
          }}
          color={theme.palette.secondary.main}
          backgroundColor={theme.palette.primary.main}
          p={0.5}
          m={0.5}
          display='inline'
        >
          FCL
        </Typography>
        <Typography
          variant='h5'
          fontWeight='bold'
          sx={{
            // display: 'block',
            // position: 'absolute',
            // top: '330px',
            // bottom: '0px',
            // left: '500px',
          }}
          color={theme.palette.secondary.main}
          backgroundColor={theme.palette.primary.main}
          p={0.5}
          m={0.5}
          display='inline'
        >
          LCL
        </Typography>
        <Typography
          variant='h5'
          fontWeight='bold'
          sx={{
            // display: 'block',
            // position: 'absolute',
            // top: '330px',
            // bottom: '0px',
            // left: '500px',
          }}
          color={theme.palette.secondary.main}
          backgroundColor={theme.palette.primary.main}
          p={0.5}
          m={0.5}
          display='inline'
        >
           {t('landingPage.sectionOne.lineThree')}
        </Typography>
      </div>
    </>
    )
  };

  const pulsateAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.10);
  }
  100% {
    transform: scale(1);
  }
`;

const TopSectionV2 = () => {
  const theme = useTheme();
  const { lang } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <Box
      ref={ref}
      sx={{
        // marginTop: '50px',
        position: 'relative',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      {/* Background Image */}
      <img
        src={BlueSea}
        alt='mainpic'
        style={{
          // position: 'absolute',
          // top: 0,
          // left: 0,
          // width: '100%',
          // height: '100%',
          // zIndex: -1,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center center', // Center the image
          zIndex: -1,
          opacity: '18%',
        }}
      />
      {/* Right Image */}
      <Slide
        direction="left"
        in={inView}
        {...(inView ? { timeout: 1000 } : {})}
        style={{ transitionDelay: inView ? '500ms' : '0ms' }}
      >
        <img
          src={BlueBoat}
          alt={'WarehouseAboutUs'}
          style={{
            position: 'absolute',
            top: '40%',
            right: 0,
            // transform: 'translate(5%, 66%)',
            // transform: 'translateY(-50%)',
            // maxWidth: '70%',
            width: '700px',
            // height: 'auto',
          }}
        />
      </Slide>
      <Box
        sx={{
          position: 'absolute',
          // top: '50%',
          // left: '20%',
          // left: isMobileView ? '50%' : '25%', // Set the left position based on screen size
          // transform: 'translate(-50%, 0%)',
          // textAlign: 'center',
          zIndex: 1, // Move the Content Box above the background image
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            height: '100%',
          }}
        >
          <Slide
            direction="down"
            in={inView}
            {...(inView ? { timeout: 1000 } : {})}
            style={{ transitionDelay: inView ? '300ms' : '0ms' }}
          >
            <div
              style={{
                display: 'block',
                position: 'absolute',
                backgroundColor: theme.palette.secondary.main,
                width: '70px',
                top: '0px',
                bottom: '0px',
                left: isMobileView ? '140px' : '190px',
                height: '100%',
                // transform: 'translateX(-50%)',
              }}
            />
          </Slide>
          <Slide
            direction="down"
            in={inView}
            {...(inView ? { timeout: 500 } : {})}
            style={{ transitionDelay: inView ? '300ms' : '0ms' }}
          >
            <div
              style={{
                display: 'block',
                position: 'absolute',
                backgroundColor: theme.palette.primary.main,
                width: '70px',
                top: '0px',
                bottom: '0px',
                left: isMobileView ? '210px' : '260px',
                height: '100%',
                // transform: 'translateX(-50%)',
              }}
            />
          </Slide>
          <Slide
            direction="right"
            in={inView}
            {...(inView ? { timeout: 6000 } : {})}
            // style={{ transitionDelay: inView ? '10ms' : '0ms' }}
          >
            <div
              style={{
                position: 'absolute',
                width: '300px',
                top: '15%',
              }}
            >
              <img
                src={lang === 'en' ? JNCPhoneUiEn : JNCPhoneUiCn}
                alt="sameslage"
                style={{
                  position: 'absolute',
                  width: '300px',
                  // marginBottom: '20px',
                  // top: '29%',
                  left: isMobileView ? '10px' : '100px',
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  width: '300px',
                  left:  isMobileView ? '40px' : '129px',
                  marginTop: '460px',
                }}
              >
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{
                    width: '240px',
                    marginBottom: theme.spacing(1),
                    color: '#FFF',
                    animation: `${pulsateAnimation} 2s infinite`,
                  }}
                  onClick={() => {
                    navigate(`/${lang}/comingsoon`);
                  }}
                >
                  {t('login')}
                </Button>
                <br/>
                <Button
                  variant='outlined'
                  color='secondary'
                  sx={{
                    width: '240px'
                  }}
                  onClick={() => {
                    navigate(`/${lang}/comingsoon`);
                  }}
                >
                  {t('signUp')}
                </Button>
              </div>
            </div>
            {/* <img
              src={JNCPhoneUi}
              alt="sameslage"
              style={{
                position: 'absolute',
                width: '300px',
                // height: '150px',
                // borderRadius: '50%',
                marginBottom: '20px',
                top: '15%',
                left: isMobileView ? '50px' : '100px',
              }}
            /> */}
          </Slide>
          {
            !isTabletView ? (
              <>
                {/* <Slide
                  direction="right"
                  in={inView}
                  {...(inView ? { timeout: 6000 } : {})}
                  // style={{ transitionDelay: inView ? '10ms' : '0ms' }}
                > */}
                  <TopSectionTextContent />
                {/* </Slide> */}
              </>
            ) : null
          }
        </div>
      </Box>
    </Box>
  );
};

const LandingPage: React.FC = () => {
  const theme = useTheme();
  const [overlayOpacity, setOverlayOpacity] = useState(0.5);
  const [contactUsDialog, setContactUsDialog] = useState<ContactUsDialogInt>({ isOpen: false });
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const maxOpacity = 1;
      const opacity = Math.min(scrollTop / (window.innerHeight / 2.7), maxOpacity);
      setOverlayOpacity(opacity < 0.5 ? 0.5 : opacity);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {/* FIRST SECTION */}
      {/* <VideoSection>
        <Video />
        <div
          id="white-overlay"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: `rgba(255, 255, 255, ${overlayOpacity})`,
            // backgroundColor: overlayOpacity !== 1 ? `rgba(255, 255, 255, ${overlayOpacity})` : '#003366',
            transition: 'background-color 0.2s',
          }}
        />
        <Text
          overlayOpacity={overlayOpacity}
        />
      </VideoSection> */}
      <TopSectionV2 />
      {isTabletView ? <TopSectionMobileTextContent /> : null}
      {/* <OperatingInCountries /> */}
      <CoreServices />
      <AboutUsSectionV2 />
      {/* <AboutUsSection /> */}
      <OurServicesV2 />
      {/* <WorldToChinaSection /> */}
      {/* <KeyFeaturesSection /> */}
      {/* <OurServicesSection /> */}
      <FocusOnWhatMattersSection
        setContactUsDialog={setContactUsDialog}
      />
      <NewsSection />
      <ContactUsDialog
        contactUsDialog={contactUsDialog}
        setContactUsDialog={setContactUsDialog}
      />
      {/* <div style={{ height: '5000px' }}></div> */}
    </div>
  );
};

export default LandingPage;

const CircularProgressWithLabel: React.FC = (
  props: CircularProgressProps & {
    value: number,
    label: number,
    displayindex: number,
    manualsetdisplayindex: () => void,
  },
) => {
  const { label, value, displayindex, manualsetdisplayindex } = props;
  return (
    <Box
      className="box-wrapper"
      sx={{ position: "relative", display: "inline-flex", cursor: 'pointer' }}
      onClick={() => manualsetdisplayindex(label - 1)}
    >
      <CircularProgress
        variant="determinate"
        // thickness={3}
        value={value}
        size='2rem'
        {...props}
        sx={{
          position: 'relative',
          zIndex: 2,
          'svg': {
            color: '#fd7702',
            circle: {
            }
          }
        }}
      />
      <CircularProgress
        variant="determinate"
        value={100}
        // thickness={3}
        size='2rem'
        sx={{
          position: 'absolute',
          zIndex: 1,
          right: 0,
          'svg': {
            color: 'grey',
            circle: {
              strokeDashoffset: '0px !important',
            },
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant='body1'
          component='div'
          fontWeight='bold'
          // color={displayindex === (Number(label) - 1) ? '#fd7702' : '#FFF'}
          color={displayindex === (Number(label) - 1) ? '#fd7702' : 'grey'}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

interface TypingEffectInterface {
  indexValue: number;
  displayIndex: number;
  content: string;
}

const TypingEffect: React.FC<TypingEffectInterface> = ({
  indexValue,
  displayIndex,
  content,
}) => {
  const [text, setText] = useState('');
  // const [typing, setTyping] = useState(false);

  useEffect(() => {
    let timeout;

    const animateText = (currentIndex) => {
      if (currentIndex > content.length) {
        return;
      }

      setText(content.substring(0, currentIndex));

      timeout = setTimeout(() => animateText(currentIndex + 1), 25);
    };

    if (displayIndex === indexValue) {
      animateText(0);
    }

    return () => clearTimeout(timeout);
  }, [content, indexValue, displayIndex]);
  return (
    <Typography
      variant='body1'
      component='div'
      fontWeight='bold'
      color='grey'
      // color='#FFF'
      sx={{
        display: indexValue === displayIndex ? 'inline' : 'none',
      }}
    >
      . {text}
    </Typography>
  );
};

interface ServicesListItemInterface {
  title: string;
  description: string;
  indexValue: number;
  displayIndex: number;
  manualSetDisplayIndex: () => void;
}

const ServicesListItem: React.FC<ServicesListItemInterface> = ({
  title,
  description,
  indexValue,
  displayIndex,
  manualSetDisplayIndex,
}) => {
  const theme = useTheme();
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    let interval = undefined;
    if (indexValue === displayIndex && !interval) {
      interval = setInterval(() => {
        setCount((prevState) => {
          if (prevState === 9.9) {
            clearInterval(interval);
            return 0;
          }
          return prevState + 0.1;
        });
      }, 100);
    }
    if (indexValue !== displayIndex) {
      clearInterval(interval);
      setCount(0);
      interval = undefined;
    }

    return () => clearInterval(interval);
  }, [displayIndex, indexValue]);
  return (
    <Grid container sx={{ marginBottom: theme.spacing(1) }}>
      <Grid item xs={2}>
        <CircularProgressWithLabel
          value={count * 10}
          label={indexValue + 1}
          manualsetdisplayindex={manualSetDisplayIndex}
          displayindex={displayIndex}
        />
      </Grid>
      <Grid
        item
        xs={10}
        sx={{
          paddingTop: '3px',
          // paddingLeft: { md: theme.spacing(5) },
          // paddingRight: { md: theme.spacing(8) },
        }}
      >
        <Typography
          variant='body1'
          component='div'
          fontWeight='bold'
          // color={displayIndex === indexValue ? '#FF5800' : '#FFFF'}
          color={displayIndex === indexValue ? '#fd7702' : '#FFFF'}
          display='inline'
          sx={{
            cursor: 'pointer'
          }}
          onClick={() => manualSetDisplayIndex(indexValue)}
        >
          {title}
        </Typography>
        <TypingEffect
          indexValue={indexValue}
          displayIndex={displayIndex}
          content={description}
        />
        {/* <Typography
          variant='body1'
          component='div'
          fontWeight='bold'
          // color='grey'
          color='#FFF'
          sx={{
            display: indexValue === displayIndex ? 'inline' : 'none',
          }}
        >
          . {description}
        </Typography> */}
      </Grid>
    </Grid>
  );
};

// interface ImageCollageInterface {
//   displayIndex: number;
// }

// const ImageCollage: React.FC<ImageCollageInterface> = ({
//   displayIndex,
// }) => {
//   const handleImageClick = (index) => {
//     setSelectedImageIndex(index);
//   };

//   const images = [
//     {
//       src: 'https://static.wixstatic.com/media/23e543_97180aeb5f0e4061bd8e580e08ed981c~mv2.jpg/v1/fill/w_512,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_97180aeb5f0e4061bd8e580e08ed981c~mv2.jpg',
//       alt: 'AirFreightImage',
//     },
//     {
//       src: 'https://static.wixstatic.com/media/23e543_bea5eb0f05544e649ab5aafabbb067d5~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_bea5eb0f05544e649ab5aafabbb067d5~mv2.jpg',
//       alt: 'ColdChainImage',
//     },
//     {
//       src: 'https://static.wixstatic.com/media/b759c6_0c6f6f2d8080424ba177b2f15bfeb2e0~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/b759c6_0c6f6f2d8080424ba177b2f15bfeb2e0~mv2.jpg',
//       alt: 'ECommerceImage',
//     },
//     {
//       src: 'https://static.wixstatic.com/media/bb6119_1212d68ef4db45428a36525d4b31f22c~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/bb6119_1212d68ef4db45428a36525d4b31f22c~mv2.jpg',
//       alt: 'FlexiBagImage',
//     },
//     {
//       src: 'https://static.wixstatic.com/media/23e543_94d7458e926049879b76ed44edf65080~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_94d7458e926049879b76ed44edf65080~mv2.jpg',
//       alt: 'ISOTankImage',
//     },
//     {
//       src: 'https://static.wixstatic.com/media/4715bd_40fb51231f884843836857eb63cd754a~mv2.jpeg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/4715bd_40fb51231f884843836857eb63cd754a~mv2.jpeg',
//       alt: 'LCLImage',
//     },
//     {
//       src: 'https://static.wixstatic.com/media/23e543_e3e226f828994283b48b2c63713aa5fd~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_e3e226f828994283b48b2c63713aa5fd~mv2.jpg',
//       alt: 'ProjectCargoImage',
//     },
//     {
//       src: 'https://static.wixstatic.com/media/bb6119_92e752ac552e470eaa83732ecc9bfe7f~mv2.png/v1/fill/w_396,h_340,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/bb6119_92e752ac552e470eaa83732ecc9bfe7f~mv2.png',
//       alt: 'SOCOpsImage',
//     },
//     {
//       src: 'https://static.wixstatic.com/media/23e543_bfc6940e019d4f1ea8388d8b4804a6fa~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_bfc6940e019d4f1ea8388d8b4804a6fa~mv2.jpg',
//       alt: 'ShipAgencyImage',
//     },
//     {
//       src: 'https://static.wixstatic.com/media/23e543_1b56d0f2555046c6b223beb7a5973b5f~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_1b56d0f2555046c6b223beb7a5973b5f~mv2.jpg',
//       alt: 'SpecialProjectCargoImage',
//     },
//   ];

//   return (
//     <Swiper
//       slidesPerView={1}
//       // spaceBetween={10}
//       centeredSlides={true}
//     >
//       {
//         images.map((image, index) => (
//           <SwiperSlide sx={{textAlign: 'center'}}>
//             <img
//               key={index}
//               src={image.src}
//               alt={image.alt}
//               onClick={() => handleImageClick(index)}
//             />
//           </SwiperSlide>
//         ))
//       }
//     </Swiper>
//   );
// };

interface OurServicesSectionInterface {
  // videoOpacity: number;
}

const OurServicesSection: React.FC<OurServicesSectionInterface> = ({
  // videoOpacity,
}) => {
  const theme = useTheme();
  // const [ref, inView] = useInView({
  //   triggerOnce: true,
  //   threshold: 0.2,
  // });
  const supplyChainOptions = {
    animationData: WarehouseAnimation,
    // animationData: FufilmentProduction,
    loop: true,
    style: {
      width: '100%',
      // height: '100vh',
    },
  };
  const { View: SupplyChainView } = useLottie(supplyChainOptions);
  const [displayIndex, setDisplayIndex] = useState<number>(1);

  useEffect(() => {
    const interval = setInterval(() => {
      // setDisplayIndex((prevIndex) => (prevIndex + 1) % 10);
      // 10 is no of items in array
      setDisplayIndex((prevIndex) => {
        if (prevIndex === 9) return 0;
        return prevIndex + 1;
      });
    }, 10000);

    return () => clearInterval(interval);
  }, [displayIndex]);

  const manualSetDisplayIndex = (selectedIndex: number) => {
    setDisplayIndex(selectedIndex);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        // backgroundColor: '#003A64',
        color: '#FFF',
        padding: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingRight: { xs: theme.spacing(5) },
        // paddingTop: 0,
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={7}
          sx={{ backgroundColor: '#FFF', width: '100%' }}
        >
          {SupplyChainView}
          {/* <ImageCollage displayIndex={displayIndex} /> */}
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            backgroundColor: theme.palette.primary.main,
            // backgroundColor: '#003A64',
            padding: theme.spacing(2),
            paddingLeft: { md: theme.spacing(4) },
            height: '80vh'
          }}
        >
          {/* <Typography variant='h4' sx={{ color: '#FFF' }} fontWeight='bold'>Solutions</Typography> */}
          {/* <Typography variant='body1' sx={{ color: '#FFF' }} fontWeight='bold'>Comprehensive</Typography> */}
          <Typography variant='h4' sx={{ color: '#FFF', marginBottom: theme.spacing(3) }} fontWeight='bold'>
            {/* Logistic Solutions */}
          </Typography>
          {/* <Typography variant='h4' sx={{ color: '#FFF', marginBottom: theme.spacing(3) }} fontWeight='bold'>FCL/LCL Solutions</Typography> */}

          <ServicesListItem
            title='Air Freight'
            description='JNC Air Freight Caters to both local and overseas clients'
            indexValue={0}
            displayIndex={displayIndex}
            manualSetDisplayIndex={manualSetDisplayIndex}
          />
          <ServicesListItem
            title='Cold Chain'
            description='A one-stop service centre, for all food chain'
            indexValue={1}
            displayIndex={displayIndex}
            manualSetDisplayIndex={manualSetDisplayIndex}
          />
          <ServicesListItem
            title='E-Commerce'
            description='JNC Air Freight Caters to both local and overseas clients.'
            indexValue={2}
            displayIndex={displayIndex}
            manualSetDisplayIndex={manualSetDisplayIndex}
          />
          <ServicesListItem
            title='Flexi-Bag'
            description='Flexi-bags (also called flexi-tanks) offer an alternative to ISO tank containers'
            indexValue={3}
            displayIndex={displayIndex}
            manualSetDisplayIndex={manualSetDisplayIndex}
          />
          <ServicesListItem
            title='ISO tank'
            description='Has more than 500 own ISO Tank'
            indexValue={4}
            displayIndex={displayIndex}
            manualSetDisplayIndex={manualSetDisplayIndex}
          />
          <ServicesListItem
            title='LCL Consolidation'
            description='LCL Consolidation to more than 50 ports destinations'
            indexValue={5}
            displayIndex={displayIndex}
            manualSetDisplayIndex={manualSetDisplayIndex}
          />
          <ServicesListItem
            title='Project Cargoes'
            description='For larger cargo transportation, JNC offers shipping for all categories'
            indexValue={6}
            displayIndex={displayIndex}
            manualSetDisplayIndex={manualSetDisplayIndex}
          />
          <ServicesListItem
            title='SOC Operation'
            description='More than 1000 own containers'
            indexValue={7}
            displayIndex={displayIndex}
            manualSetDisplayIndex={manualSetDisplayIndex}
          />
          <ServicesListItem
            title='Ship Agencies'
            description='As booking agent for APS'
            indexValue={8}
            displayIndex={displayIndex}
            manualSetDisplayIndex={manualSetDisplayIndex}
          />
          <ServicesListItem
            title='Special Project Cargo'
            description='We specialise in the handling and storage for your special cargo'
            indexValue={9}
            displayIndex={displayIndex}
            manualSetDisplayIndex={manualSetDisplayIndex}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

// const OurServices = () => {
//   const theme = useTheme();
//   const progressCircle = useRef(null);
//   const progressContent = useRef(null);
//   const onAutoplayTimeLeft = (s, time, progress) => {
//     if (progressCircle.current) progressCircle.current.style.setProperty('--progress', 1 - progress);
//     if (progressContent.current) progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
//   };

//   return (
//     <Container
//       maxWidth="xl"
//       sx={{
//         // backgroundColor: '#EEEEEE',
//         padding: '30px 0',
//         textAlign: 'center'
//       }}
//     >
//       <Typography
//         variant='h5'
//         color='secondary'
//         fontWeight='bold'
//       >
//         Our Services
//       </Typography>
//       <Divider
//         sx={{
//           color: theme.palette.secondary.main,
//           backgroundColor: theme.palette.secondary.main,
//           width: '10%',
//           minWidth: '30px',
//           height: '5px',
//           textAlign: 'center',
//           margin: 'auto',
//         }}
//       />
//       <Swiper
//         slidesPerView={1}
//         spaceBetween={30}
//         centeredSlides={true}
//         autoplay={{
//           delay: 2500,
//           disableOnInteraction: false,
//         }}
//         // pagination={{
//         //   clickable: false,
//         // }}
//         // navigation={false}
//         modules={[
//           // Autoplay,
//           // Pagination,
//           // Navigation
//         ]}
//       // onAutoplayTimeLeft={onAutoplayTimeLeft}
//       >
//         <SwiperSlide>
//           <OurServiceItem
//             title='Air Frieght'
//             image='https://static.wixstatic.com/media/23e543_97180aeb5f0e4061bd8e580e08ed981c~mv2.jpg/v1/fill/w_512,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_97180aeb5f0e4061bd8e580e08ed981c~mv2.jpg'
//           />
//         </SwiperSlide>
//         <SwiperSlide>
//           <OurServiceItem
//             title='Cold Chain'
//             image='https://static.wixstatic.com/media/23e543_bea5eb0f05544e649ab5aafabbb067d5~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/23e543_bea5eb0f05544e649ab5aafabbb067d5~mv2.jpg'
//           />
//         </SwiperSlide>
//         <SwiperSlide>
//           <OurServiceItem
//             title='E-Commerce'
//             image='https://static.wixstatic.com/media/b759c6_0c6f6f2d8080424ba177b2f15bfeb2e0~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/b759c6_0c6f6f2d8080424ba177b2f15bfeb2e0~mv2.jpg'
//           />
//         </SwiperSlide>
//         <SwiperSlide>
//           <OurServiceItem
//             title='Flexi-Bag'
//             image='https://static.wixstatic.com/media/bb6119_1212d68ef4db45428a36525d4b31f22c~mv2.jpg/v1/fill/w_396,h_340,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/bb6119_1212d68ef4db45428a36525d4b31f22c~mv2.jpg'
//           />
//         </SwiperSlide>
//         {/* <SwiperSlide>Slide 5</SwiperSlide>
//         <SwiperSlide>Slide 6</SwiperSlide>
//         <SwiperSlide>Slide 7</SwiperSlide>
//         <SwiperSlide>Slide 8</SwiperSlide>
//         <SwiperSlide>Slide 9</SwiperSlide> */}
//       </Swiper>
//     </Container>
//   );
// };

// interface OurServiceItemInt {
//   title: string;
//   image: string;
// }

// const OurServiceItem: React.FC<OurServiceItemInt> = ({
//   title,
//   image,
// }) => {
//   return (
//     <Card
//       // sx={{ width: '100%' }}
//       variant='outlined'
//     >
//       <CardMedia
//         sx={{ height: 190 }}
//         image={image}
//         title={title}
//       />
//       {/* <CardContent></CardContent> */}
//       <CardActions sx={{ alignContent: 'center' }}>
//         <Button size="small">{title}</Button>
//       </CardActions>
//     </Card>
//   );
// };

// const ThirdSection = () => {
//   return (
//     <>
//       <Container
//         maxWidth="xl"
//         sx={{
//           // backgroundColor: '#03486e',
//           padding: '30px 0',
//           textAlign: 'center'
//         }}
//       >
//         <Typography
//           variant='h5'
//           color='secondary'
//           fontWeight='bold'
//         >
//           Comprehensive China - Singapore Shipping Services
//         </Typography>
//         <Typography
//           variant='h2'
//           fontWeight='bold'
//           display='inline'
//         // color='#FFF'
//         >
//           Comprehensive China - Singapore Shipping Services
//         </Typography>
//       </Container>
//     </>
//   );
// };
