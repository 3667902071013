import React from 'react';
import {
  useTheme, Dialog, DialogTitle, DialogActions,
  Card, CardMedia, CardContent, CardActions,
  Typography, Button,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export interface ServiceDialogInterface {
  isOpen: boolean;
  title: String | undefined;
  description: String | undefined;
  image: String | any | undefined;
}

export interface ServiceDialogProps {
  serviceDialog: ServiceDialogInterface;
  setServiceDialog: React.Dispatch<React.SetStateAction<ServiceDialogInterface>>;
}

const ServiceDialog: React.FC<ServiceDialogProps> = ({
  serviceDialog,
  setServiceDialog,
}) => {
  const theme = useTheme();
  const {
    isOpen,
    title,
    description,
    image,
  } = serviceDialog;

  const handleClose = () => {
    setServiceDialog({
      isOpen: false,
      title: '',
      description: '',
      image: '',
    });
  };

  return (
    <Dialog onClose={handleClose} open={isOpen} fullWidth>
      {/* <DialogTitle>{title}</DialogTitle> */}
      <Card sx={{ padding: theme.spacing(1) }}>
        <CardMedia
          sx={{
            height: 400,
          }}
          image={image}
          title="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            startIcon={<HighlightOffIcon fontSize='small' />}
            size="small"
            onClick={handleClose}
          >
            Close
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
};

export default ServiceDialog;
