import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  styled, Box, Fade, Grid,
  useTheme, Typography, Button, Divider,
  Card, CardContent, CardActionArea, CardMedia,
  Stack,
} from '@mui/material';
import { useInView } from 'react-intersection-observer';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

import TeamPicture from '../../images/MCCQIANHAI.jpeg';
import { useTranslation } from 'react-i18next';

const ImageBackground = styled(Box)(({ url, opacity, backgroundColor }: { url: string; opacity: number; backgroundColor?: string }) => ({
  position: 'relative',
  minHeight: '400px', // Set the minimum height of the section to ensure it's visible
  display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // backgroundImage: `linear-gradient(to bottom, rgba(255, 165, 0, 0.5), rgba(255, 165, 0, 0.5)), url(${myImage})`
    backgroundImage: `
      ${backgroundColor
        ? `linear-gradient(to bottom, ${backgroundColor}, ${backgroundColor})`
        : 'linear-gradient(to bottom, rgba(255, 165, 0, 0), rgba(255, 165, 0, 0))'
      }, url(${url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity, // Set the opacity of the background image to 0.5
  },
}));

const ImageBackgroundContent = styled(Box)({
  position: 'relative',
  zIndex: 1,
  color: '#fff', // Set the text color to white to ensure it's visible on the background
});

interface NewsSectionInterface {

}

const NewsSection: React.FC<NewsSectionInterface> = ({}) => {
  const theme = useTheme();
  const { lang } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <Fade in={inView} timeout={3500}>
      <ImageBackground
        url='/ShippingBackground.png'
        opacity={0.15}
        className='testingClass'
        ref={ref}
      >
        <ImageBackgroundContent
          // textAlign="center"
          sx={{
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
          }}
        >
          <Typography
            variant='caption'
            fontWeight='bold'
            display='inline'
            color='secondary'
            sx={{
              // paddingTop:theme.spacing(3),
              // paddingBottom: theme.spacing(2)
            }}
          >
            {t('articles.title')}
          </Typography>
          {/* <SectionTitle title='Latest News' /> */}
          <Grid container sx={{ paddingTop: theme.spacing(2) }} spacing={2}>
            <Grid item xs={12} md={6}>
              <MainNews
                dateString='07/07/2023'
                title={t('articles.mcc-qianhai.title')}
                shortDescription={t('articles.mcc-qianhai.shortDescription')}
                navigateUrl={`/${lang}/news/mcc-qianhai`}
              />
            </Grid>
            {/* <Grid item xs={12} md={6}></Grid> */}
            <Grid item xs={12} md={6} sx={{ textAlign: 'center', paddingTop: theme.spacing(2) }}>
              <Subnews
                dateString='07/07/2023'
                title={t('articles.jnc-china-thailand.title')}
                shortDescription={t('articles.jnc-china-thailand.shortDescription')}
                navigateUrl={`/${lang}/news/jnc-china-thailand`}
              />
              <Subnews
                dateString='07/07/2023'
                title={t('articles.jnc-evs.title')}
                shortDescription={t('articles.jnc-evs.shortDescription')}
                navigateUrl={`/${lang}/news/jnc-evs`}
              />
              <Subnews
                dateString='07/07/2023'
                title={t('articles.jnc-new-cfs.title')}
                shortDescription={t('articles.jnc-new-cfs.shortDescription')}
                navigateUrl={`/${lang}/news/jnc-new-cfs`}
              />
              <Button
                variant='contained'
                color='secondary'
                sx={{ color: '#FFF', marginTop: theme.spacing(2) }}
                onClick={() => {
                  navigate(`/${lang}/news`);
                }}
              >
                {t('articles.viewAllNews')}
              </Button>
            </Grid>
          </Grid>
        </ImageBackgroundContent>
      </ImageBackground>
    </Fade>
  );
};

export default NewsSection;

const Subnews: React.FC<MainNewsInterface> = ({
  dateString,
  title,
  shortDescription,
  navigateUrl,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box
      sx={{
        height: '25%',
        textAlign: 'left',
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(2),
      }}
      onClick={() => {
        navigate(navigateUrl);
      }}
    >
      <Divider
        color={theme.palette.primary.light}
        sx={{
          marginBottom: theme.spacing(1)
        }}
      />
      <Stack
        direction='row'
        // spacing={2}
        justifyContent='space-between'
        // justifyContent='center'
      >
        <Typography
          gutterBottom
          variant="caption"
          component="div"
          color='secondary'
        >
          {dateString}
        </Typography>
        <ArrowOutwardIcon
          fontSize='small'
          color='secondary'
          sx={{
            transition: 'font-size 0.3s',
            cursor: 'pointer'
          }}
          className={isHovered ? 'icon hovered' : 'icon'}
        />
      </Stack>
      <Typography
        variant="h5"
        fontWeight='bold'
        sx={{
          cursor: 'pointer',
          '&:hover': {
            '& ~ .icon': {
              fontSize: '2rem',
            },
          },
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        // color={theme.palette.primary.light}
      >
        {shortDescription}
      </Typography>
    </Box>
  );
};

interface MainNewsInterface {
  dateString: String;
  title: string;
  shortDescription: string;
  navigateUrl: string;
}

const MainNews: React.FC<MainNewsInterface> = ({
  dateString,
  title,
  shortDescription,
  navigateUrl,
}) => {
  // const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => {
        navigate(navigateUrl);
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="100%"
          image={TeamPicture}
          alt="green iguana"
          sx={{
            minHeight: '300px',
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />
        <CardContent>
          {/* <Typography gutterBottom variant="caption" component="div" color='secondary'>
            {dateString}
          </Typography> */}
          <Stack
            direction='row'
            // spacing={2}
            justifyContent='space-between'
            // justifyContent='center'
          >
            <Typography
              gutterBottom
              variant="caption"
              component="div"
              color='secondary'
            >
              {dateString}
            </Typography>
            <ArrowOutwardIcon
              fontSize='small'
              color='secondary'
              sx={{
                transition: 'font-size 0.3s',
                cursor: 'pointer'
              }}
              // className={isHovered ? 'icon hovered' : 'icon'}
            />
          </Stack>
          <Typography variant="h5" fontWeight='bold'>
            {title}
          </Typography>
          <Typography
            variant="body1"
            // color={theme.palette.primary.light}
          >
            {shortDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
