import React, { useEffect, Suspense } from 'react';
import {
  BrowserRouter as Router, Routes, Route, Navigate,
  useParams, useNavigate
} from 'react-router-dom';
import {
  ThemeProvider, createTheme, responsiveFontSizes,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import AppTopBar from './AppTopBar';
import Footer from './Footer';
import LoadingSpinner from '../utility/LoadingSpinner';
import LandingPage from '../../pages/LandingPage';
import AboutUsPage from '../../pages/AboutUs';
import SchedulePage from '../../pages/Schedule';
import ServicesPage from '../../pages/ServicesPage';
import NewsPage from '../../pages/NewsPage';
import NewsDetailPage from '../../pages/NewsDetailPage';
import ComingSoonPage from '../../pages/ComingSoon';


/**
 * 0D0F23
 * 125DE0
 */
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#003366',
      // main: '#006CA6',
      // main: 'red',
      light: '#EDEDED',
    },
    secondary: {
      main: '#fd7702',
      light: '#F2F3F8',
      // main: '#000',
    },
    // otherColor: {
    //   main: '#999',
    // },
  },
  typography: {
    fontFamily: 'CustomFont, sans-serif',
    // fontSize: 10,
    allVariants: {
      color: '#003366'
      // color: '#03486e'
    }
  },
});

const AppRouter: React.FC = () => {
  const theme = responsiveFontSizes(lightTheme);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route
              path='/:lang/*'
              element={<LandingPageRoute />}
            />
            <Route
              path="*"
              element={<Navigate to="/en" replace />}
            />
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  );
};

export default AppRouter;

const LandingPageRoute = () => {
  const { lang } = useParams();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
  }, [i18n, lang]);

  useEffect(() => {
    if (lang !== 'en' && lang !== 'cn') {
      navigate('/en');
    }
  }, [lang, navigate]);

  return (
    <>
      <AppTopBar />
      <Routes>
        <Route
          path='/'
          element={<LandingPage />}
        />
        <Route
          path='/about'
          element={<AboutUsPage />}
        />
        <Route
          path='/services'
          element={<ServicesPage />}
        />
        <Route
          path='/schedule'
          element={<SchedulePage />}
        />
        <Route
          path='/news'
          element={<NewsPage />}
        />
        <Route
          path='/news/:id'
          element={<NewsDetailPage />}
        />
        <Route
          path='/comingsoon'
          element={<ComingSoonPage />}
        />
      </Routes>
      <Footer/>
    </>
  );
};
