import React, { useEffect, useState } from 'react';
import {
  useTheme, styled, Divider, useMediaQuery,
  Typography, Box, Grid, Paper, Stack,
  Card, CardHeader, Collapse, CardContent,
  IconButton, IconButtonProps, Fade,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import WorldConnection from '../../images/ExportToChina.png';
import MccArticleImage from '../../images/MccArticleImage.png';
import { servicesList } from '../ServicesPage/index';

const OurServices: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      paddingTop={theme.spacing(4)}
      paddingLeft={theme.spacing(4)}
      paddingRight={theme.spacing(4)}
      paddingBottom={theme.spacing(4)}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={6}
      >
        <KeyFeaturesItem
          icon={
            <SwapHorizontalCircleIcon
              // color='primary'
              fontSize='large'
              sx={{ fontSize: '80px', color: theme.palette.primary.main }}
            />
          }
          title={t('landingPage.sectionThree.keyFeatures.one.title')}
          subTitle={t('landingPage.sectionThree.keyFeatures.one.description')}
        />
        <KeyFeaturesItem
          icon={
            <ReceiptLongIcon
              fontSize='large'
              sx={{ fontSize: '80px', color: theme.palette.primary.main }}
            />
          }
          title={t('landingPage.sectionThree.keyFeatures.two.title')}
          subTitle={t('landingPage.sectionThree.keyFeatures.two.description')}
        />
        <KeyFeaturesItem
          icon={
            <DirectionsBoatIcon
              fontSize='large'
              sx={{ fontSize: '80px', color: theme.palette.primary.main }}
            />
          }
          title={t('landingPage.sectionThree.keyFeatures.three.title')}
          subTitle={t('landingPage.sectionThree.keyFeatures.three.description')}
        />
        <KeyFeaturesItem
          icon={
            <WarehouseIcon
              fontSize='large'
              sx={{ fontSize: '80px', color: theme.palette.primary.main }}
            />
          }
          title={t('landingPage.sectionThree.keyFeatures.four.title')}
          subTitle={t('landingPage.sectionThree.keyFeatures.four.description')}
        />
      </Grid>
      <Box
        sx={{
          paddingBottom: theme.spacing(3)
        }}
      >
        <Divider
          sx={{
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main,
            width: '70px',
            height: '3px',
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(1),
          }}
        />
        <Typography
          variant='caption'
          fontWeight='bold'
        >
          {t('landingPage.sectionThree.lineOne')}
        </Typography>
      </Box>
      <Typography
        variant='h4'
        maxWidth={{
          xs: '100%',
          md: '60%',
        }}
      >
        {t('landingPage.sectionThree.lineTwo')}
      </Typography>
      <ServicesDisplay />
      <FeatureShipToChina />
    </Box>
  );
};

export default OurServices;

const FeatureShipToChina = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={7}>
          <Typography variant="h4" fontWeight='bold' color='primary' paddingTop={theme.spacing(1)}>{t('landingPage.sectionThree.lineThree')}</Typography>
          <Typography variant="body1" color='primary' paddingTop={theme.spacing(2)} paddingBottom={theme.spacing(2)}>
            {t('landingPage.sectionThree.lineFour')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <img
            src={MccArticleImage}
            alt='MCC-Qianhai'
            width='100%'
          />
        </Grid>
      </Grid>
    </Box>
  );
};

interface KeyFeaturesItemInterface {
  icon: any;
  title: string;
  subTitle?: string;
}

const KeyFeaturesItem: React.FC<KeyFeaturesItemInterface> = ({
  icon,
  title,
  subTitle,
}) => {
  return (
    <Grid item xs={6} md={3} sx={{ textAlign: 'center' }}>
      {icon}
      <Typography
        variant='body1'
        fontWeight='bold'
        // color='#FFF'
      >
        {title}
      </Typography>
      <Typography
        variant='body1'
        // color='#FFF'
      >
        {subTitle || ''}
      </Typography>
    </Grid>
  );
};

const RootContainer = styled('div')({
  position: 'relative',
  height: '70vh',
  width: '100%',
  backgroundImage: `url(${WorldConnection})`, // Replace with the path to your image
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  borderRadius: '25px',
  // boxShadow: `0px 10 0px 0px white inset`,
  // boxShadow: `5px 5px 0 0 #FFF`,
});

const DarkOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.15)', // Adjust the opacity and color as needed
  borderRadius: '25px',
});

const Content = styled('div')({
  position: 'absolute',
  // bottom: '5%',
  // paddingLeft: '3%',
  // right: '10%',
  // transform: 'translate(-50%, -50%)',
  // textAlign: 'center',
  color: '#fff',
  width: '100%',
  borderRadius: '25px',
});

const ServicesDisplay: React.FC = () => {
  const theme = useTheme();
  const [selectedService, setSelectedService] = useState<number>(0);
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        paddingTop: theme.spacing(4),
        // paddingLeft: theme.spacing(4),
        // paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        width: '100%'
      }}
    >
      <Paper
        variant='outlined'
        sx={{
          width: '100%',
          borderRadius: '25px',
          borderWidth: '3px',
          borderColor: theme.palette.primary.main,
          // outline: `5px solid ${theme.palette.secondary.main}`,
        }}
      >
        <RootContainer>
          <DarkOverlay></DarkOverlay>
          <Content sx={{ padding: theme.spacing(3) }}>
            <Stack
              direction='row'
              // gap={1}
            >
              <ServiceList
                selectedService={selectedService}
                setSelectedService={setSelectedService}
              />
              {
                !isTabletView ? (
                  <ServiceDetail
                    selectedService={selectedService}
                  />
                ) : null
              }
            </Stack>
          </Content>
        </RootContainer>
      </Paper>
    </Box>
  );
};

interface ServiceListProps {
  selectedService: number;
  setSelectedService: React.Dispatch<React.SetStateAction<number>>;
}

const ServiceList: React.FC<ServiceListProps> = ({
  selectedService,
  setSelectedService,
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));
  
  return (
    <Box
      width={isTabletView || isMobileView ? '100%' : '30%'}
      height='60vh'
      sx={{
        overflow: 'auto',
      }}
    >
      {
        servicesList.map((serviceItem, key) => (
          <ServiceListItem
            key={`serviceList-${key}`}
            seq={key}
            selectedService={selectedService}
            setSelectedService={setSelectedService}
          />
        ))
      }
    </Box>
  );
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface ServiceListItemProps {
  seq: number;
  selectedService: number;
  setSelectedService: React.Dispatch<React.SetStateAction<number>>;
}

const ServiceListItem: React.FC<ServiceListItemProps> = ({
  seq,
  selectedService,
  setSelectedService,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));
  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    if (!isTabletView) setExpanded(false);
  }, [isTabletView]);

  const boxOutlineColor = () => {
    if (isTabletView) {
      if (expanded) return theme.palette.primary.main;
      return '';
    };
    if (seq === selectedService) return theme.palette.primary.main;
    return '';
  };
  const contentColor = () => {
    if (isTabletView) {
      if (expanded) return 'secondary';
      return 'primary';
    }
    if (seq === selectedService) return 'secondary';
    return 'primary';
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const IconTest = servicesList[seq].icon;
  const serviceId = servicesList[seq].id;
  const serviceTitle = t(`services.${serviceId}.title`);
  const serviceDescription = t(`services.${serviceId}.description`);
  const serviceImage = servicesList[seq].image;

  return (
    <Card
      sx={{
        width: '100%',
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
        borderWidth: '2px',
        borderColor: boxOutlineColor(),
      }}
      variant='outlined'
    >
      <CardHeader
        avatar={
          <IconTest
            fontSize='large'
            color={contentColor()}
          />
        }
        action={
          isTabletView ? (
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          ) : null
        }
        title={<Typography variant='h6' color={contentColor()}>{serviceTitle}</Typography>}
        // subheader="September 14, 2016"
        onClick={() => {
          if (isTabletView) {
            handleExpandClick();
          } else {
            setSelectedService(seq);
          }
        }}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {/* <Typography
            variant='h5'
            fontStyle='italic'
          >
            {serviceTitle}
          </Typography> */}
          <Typography
            variant='body1'
          >
            {serviceDescription}
          </Typography>
            <Box
              justifyItems='center'
              paddingTop={theme.spacing(2)}
              width='100%'
              textAlign='center'
            >
              <img
                src={serviceImage}
                alt="serviceImage"
                style={{
                  width: '100%',
                }}
              />
            </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};

interface ServiceDetailProps {
  selectedService: number;
  // setSelectedService: React.Dispatch<React.SetStateAction<number>>;
}

const ServiceDetail: React.FC<ServiceDetailProps> = ({
  selectedService,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const serviceId = servicesList[selectedService].id;
  const IconTest = servicesList[selectedService].icon;
  const serviceTitle = t(`services.${serviceId}.title`);
  const serviceDescription = t(`services.${serviceId}.description`);
  const serviceImage = servicesList[selectedService].image;

  return (
    <Box
      width='70%'
      padding={theme.spacing(2)}
      paddingLeft={theme.spacing(4)}
      sx={{
        height: '60vh',
        overflow: 'auto',
      }}
    >
      <Stack direction='row' gap={4}>
        <IconTest
          color='secondary'
          sx={{
            fontSize: '100px',
          }}
        />
        <div>
          <Typography
            variant='h2'
            fontStyle='italic'
          >
            {serviceTitle}
          </Typography>
          <Typography
            variant='body1'
          >
            {serviceDescription}
          </Typography>
        </div>
      </Stack>
      <Box
        justifyItems='center'
        paddingTop={theme.spacing(2)}
        width='100%'
        paddingLeft={theme.spacing(16)}
      >
        <img
          src={serviceImage}
          alt="serviceImage"
          style={{
            // height: '50%'
          }}
        />
      </Box>
    </Box>
  );
};