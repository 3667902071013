import React from 'react';
import {
  useTheme, styled, useMediaQuery, Box, Paper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import WorldConnection from '../../images/ExportToChina.png';

const RootContainer = styled('div')({
  position: 'relative',
  height: '70vh',
  width: '100%',
  backgroundImage: `url(${WorldConnection})`, // Replace with the path to your image
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  borderRadius: '25px',
  // boxShadow: `0px 10 0px 0px white inset`,
  // boxShadow: `5px 5px 0 0 #FFF`,
});

const DarkOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.15)', // Adjust the opacity and color as needed
  borderRadius: '25px',
});

const Content = styled('div')({
  position: 'absolute',
  // bottom: '5%',
  // paddingLeft: '3%',
  // right: '10%',
  // transform: 'translate(-50%, -50%)',
  // textAlign: 'center',
  color: '#fff',
  width: '100%',
  borderRadius: '25px',
});

const ComingSoonPage: React.FC = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        paddingTop: theme.spacing(4),
        // paddingLeft: theme.spacing(4),
        // paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        // width: '100%',
        margin: theme.spacing(4),
        marginTop: theme.spacing(10),
      }}
    >
      <Paper
        variant='outlined'
        sx={{
          width: '100%',
          borderRadius: '25px',
          borderWidth: '3px',
          borderColor: theme.palette.primary.main,
          // outline: `5px solid ${theme.palette.secondary.main}`,
        }}
      >
        <RootContainer>
          <DarkOverlay></DarkOverlay>
          <Content sx={{ padding: theme.spacing(3), color: 'blue', textAlign: 'center' }}>
            <Typography
              variant='h1'
              color='secondary'
              sx={{
                fontSize: isMobileView ? '2rem' : '',
                marginBottom: theme.spacing(2),
              }}
            >
              {t('construction.title')}
            </Typography>
            <Typography variant='h5' sx={{ marginBottom: theme.spacing(2) }}>
              {t('construction.description')}
            </Typography>
            <Typography variant='h5'>
              {t('construction.stayTuned')}
            </Typography>
          </Content>
        </RootContainer>
      </Paper>
    </Box>
  )
};

export default ComingSoonPage;
