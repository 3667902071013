import React from 'react';
import './App.css';
import { CssBaseline} from '@mui/material';

import AppRouter from './components/navigation/AppRouter';

const App: React.FC = () => {
  return (
    <div className="App">
      <CssBaseline/>
      <AppRouter />
    </div>
  );
}

export default App;
