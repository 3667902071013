import React, { useRef } from 'react';
import {
  useTheme, useMediaQuery, Box, Typography,
  Divider, Slide
} from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

import WarehouseActual from "../../images/WarehouseActual.jpeg";

const AboutUsSectionV2: React.FC = () => {
  const theme = useTheme();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));
  const { t, i18n } = useTranslation();

  return (
    <Box
      ref={ref}
      sx={{
        // marginTop: '50px',
        position: 'relative',
        height: '70vh',
        overflow: 'hidden',
      }}
    >
      <img
        src={WarehouseActual}
        alt='WarehousePic'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center center', // Center the image
          zIndex: -1,
          opacity: '60%',
        }}
      />
      <Slide direction='right' in={inView} mountOnEnter unmountOnExit timeout={5000}>
        <Box
          sx={{
            position: 'absolute',
            // top: '50%',
            left: isTabletView ? '10px' : '100px',
            bottom: '0vh',
            // left: isMobileView ? '50%' : '25%', // Set the left position based on screen size
            // transform: 'translate(-50%, 0%)',
            // textAlign: 'center',
            zIndex: 1, // Move the Content Box above the background image
            width: isTabletView ? '90%' : '50%',
            height: '70%',
            backgroundColor: '#FFF',
            // transform: 'translate(100%)',
            borderTopLeftRadius: '30px',
            borderTopRightRadius: '30px',
            overflow: 'auto'
          }}
        >
          <Box
            sx={{
              padding: theme.spacing(4),
            }}
          >
            <Box
              sx={{
                paddingBottom: theme.spacing(3)
              }}
            >
              <Divider
                sx={{
                  color: theme.palette.secondary.main,
                  backgroundColor: theme.palette.secondary.main,
                  width: '70px',
                  height: '3px',
                  marginBottom: theme.spacing(1),
                }}
              />
              <Typography
                variant='caption'
                fontWeight='bold'
              >
                {t('landingPage.sectionTwo.lineOne')}
              </Typography>
              <Typography
                variant='h4'
                fontWeight='bold'
                sx={{
                  marginTop: theme.spacing(2),
                }}
              >
                {t('landingPage.sectionTwo.lineTwo')}
              </Typography>
              <Typography
                variant='h4'
                fontWeight='bold'
                sx={{
                  marginBottom: theme.spacing(2),
                  // color: overlayOpacity === 1 ? '#FFF' : '',
                  // transition: 'color 0.2s',
                  fontSize: {
                    // xs: '3rem',
                  },
                }}
              >
                {t('landingPage.sectionTwo.lineThree')}
              </Typography>
              <Typography
                // color='secondary'
                display='inline'
                // fontWeight='bold'
              >
                {/* JNC Logistics Pte Ltd&nbsp; */}
                {t('landingPage.sectionTwo.lineFour')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Box>
  );
};

export default AboutUsSectionV2;
