import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import {
  styled, useTheme, Typography, Container,
  Grid, Box, Paper, Stack, CardMedia, Divider,
  // Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CircleIcon from '@mui/icons-material/Circle';

import articles, { ArticleInterface } from '../NewsDetailPage/articles';
import MCCArticleImage from '../../images/MccArticleImage.png';
import Bagimg from '../../images/WarehouseAboutUS.jpg';
import TeamPicture from '../../images/MCCQIANHAI.jpeg';
import { useTranslation } from 'react-i18next';

const RootContainer = styled('div')({
  position: 'relative',
  height: '40vh',
  backgroundImage: `url(${Bagimg})`, // Replace with the path to your image
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
});

const DarkOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.55)', // Adjust the opacity and color as needed
});

const Content = styled('div')({
  position: 'absolute',
  bottom: '5%',
  paddingLeft: '3%',
  // right: '10%',
  // transform: 'translate(-50%, -50%)',
  textAlign: 'left',
  color: '#fff',
});

const NewsPage: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <RootContainer>
        <DarkOverlay></DarkOverlay>
        <Content>
          <>
            <Typography variant="h4" fontWeight='bold' color='secondary'>{t('articles.title')}</Typography>
          </>
        </Content>
      </RootContainer>
        <div
          style={{
            backgroundColor: theme.palette.secondary.light,
          }}
        >
          <Container maxWidth='lg' sx={{ paddingTop: theme.spacing(4) }}>
            <FeaturedNews
              key={articles[0].id}
              id={articles[0].id}
              dateString={articles[0].dateString}
              title={articles[0].title}
              description={articles[0].description}
              image={articles[0].image}
            />
          </Container>
          <Box
            sx={{
              background: `linear-gradient(to bottom, ${theme.palette.secondary.light} 50%, #FFF 50%)`,
              // background: 'linear-gradient(to bottom, #003A64 50%, #FFF 50%)',
              // height: '0vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              // alignItems: 'center',
              paddingTop: theme.spacing(4),
            }}
          >
            <Container maxWidth='lg'>
              <Grid container spacing={2}>
                <NewsItemV2
                  key={articles[1].id}
                  id={articles[1].id}
                  dateString={articles[1].dateString}
                  title={t(`articles.${articles[1].id}.title`)}
                  description={t(`articles.${articles[1].id}.shortDescription`)}
                  image={articles[1].image}
                />
                <NewsItemV2
                  key={articles[2].id}
                  id={articles[2].id}
                  dateString={articles[2].dateString}
                  title={t(`articles.${articles[2].id}.title`)}
                  description={t(`articles.${articles[2].id}.shortDescription`)}
                  image={articles[2].image}
                />
                {/* <Grid item xs={12} md={6}>
                  asd
                </Grid> */}
              </Grid>
            </Container>
          </Box>
          <div style={{ backgroundColor: '#FFF' }}>
            <Container maxWidth='lg' sx={{ backgroundColor: '#FFF' }}>
              <Grid container spacing={2}>
                <NewsItemV2
                  key={articles[3].id}
                  id={articles[3].id}
                  dateString={articles[3].dateString}
                  title={t(`articles.${articles[3].id}.title`)}
                  description={t(`articles.${articles[3].id}.shortDescription`)}
                  image={articles[3].image}
                />
              </Grid>
            </Container>
          </div>
        </div>
    </>
    // <>
    //   <RootContainer>
    //     <DarkOverlay></DarkOverlay>
    //     <Content>
    //       <>
    //         <Typography variant="h4" fontWeight='bold' color='secondary'>LATEST NEWS</Typography>
    //       </>
    //     </Content>
    //   </RootContainer>
    //   <Container
    //     // sx={{ backgroundColor: 'red' }}
    //     maxWidth='md'
    //   >
    //     {
    //       articles.map((newsItem: ArticleInterface) => (
    //         <NewsItem
    //           key={newsItem.id}
    //           id={newsItem.id}
    //           dateString={newsItem.dateString}
    //           title={newsItem.title}
    //           description={newsItem.description}
    //           image={newsItem.image}
    //         />
    //       ))
    //     }
    //   </Container>
    // </>
  );
};

export default NewsPage;

const FeaturedNews: React.FC<ArticleInterface> = ({
  id,
  dateString,
  title,
  description,
  image,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isHover, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box
      sx={{
        cursor: 'pointer',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        navigate(id);
      }}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12} md={8}>
          <ImageContainer>
            <Image />
          </ImageContainer>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            // borderBottom: `2px solid #D3D3D3`,
          }}
        >
          <Stack direction='row' gap={1} alignItems='center'>
            <Typography variant='caption' fontWeight='bold'>{t('articles.articles')}</Typography>
            <CircleIcon sx={{ fontSize: '6px' }} />
            <Typography variant='caption' color='secondary'>{t('articles.companyProject')}</Typography>
          </Stack>
          <Typography
            variant="h5"
            fontWeight='bold'
            paddingTop={theme.spacing(1)}
            paddingBottom={theme.spacing(1)}
            color={isHover ? 'secondary' : ''}
            sx={{
              transition: 'color 0.4s ease',
            }}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          >
            {t(`articles.${articles[0].id}.title`)}
          </Typography>
          <Typography
            variant="body1"
          // color={theme.palette.primary.light}
          >
            {t(`articles.${articles[0].id}.shortDescription`)}
          </Typography>
          <Typography
            gutterBottom
            variant="caption"
            component="div"
            paddingTop={theme.spacing(1)}
          // color='secondary'
          >
            {articles[0].dateString}
          </Typography>
          <Divider sx={{ bottom: 0 }} />
        </Grid>
      </Grid>
    </Box>
  );
};

const NewsItemV2: React.FC<ArticleInterface> = ({
  id,
  dateString,
  title,
  description,
  image,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isHover, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Grid
      item
      xs={12}
      md={6}
      onClick={() => {
        navigate(id);
      }}
      sx={{
        cursor: 'pointer',
      }}
      marginBottom={theme.spacing(4)}
    >
      <Box
        sx={{
          width: `100%`,
          // height: `35vh`,
          // minHeight: '35vh',
          // overflow: 'hidden',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box width='100%' height='45vh'>
          <img
            src={image}
            alt="teampic"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
        <Stack direction='row' gap={1} alignItems='center' marginTop={theme.spacing(2)}>
          <Typography variant='caption' fontWeight='bold'>{t('articles.articles')}</Typography>
          <CircleIcon sx={{ fontSize: '6px' }} />
          <Typography variant='caption' color='secondary'>{t('articles.companyProject')}</Typography>
        </Stack>
        <Typography
          variant="h5"
          fontWeight='bold'
          paddingTop={theme.spacing(1)}
          paddingBottom={theme.spacing(1)}
          color={isHover ? 'secondary' : ''}
          sx={{
            transition: 'color 0.4s ease',
          }}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
        // color={theme.palette.primary.light}
        >
          {description}
        </Typography>
        <Typography
          gutterBottom
          variant="caption"
          component="div"
          paddingTop={theme.spacing(1)}
        // color='secondary'
        >
          {dateString}
        </Typography>
        <Divider sx={{ bottom: 0 }} />
      </Box>
    </Grid>
  );
};

const NewsItem: React.FC<ArticleInterface> = ({
  id,
  dateString,
  title,
  description,
  image,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Paper
      variant='outlined'
      sx={{
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        maxHeight: {
          md: '220px'
        },
        pointer: 'cursor',
      }}
      onClick={() => {
        navigate(id);
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <div
            style={{
              width: `100%`,
              height: `150px`,
              overflow: 'hidden',
            }}
          >
            <img
              src={TeamPicture}
              alt="teampic"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={9}>
          <Stack
            direction='row'
            // spacing={2}
            justifyContent='space-between'
          // justifyContent='center'
          >
            <Typography
              variant="h5"
              fontWeight='bold'
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  '& ~ .icon': {
                    fontSize: '2rem',
                  },
                },
              }}
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            >
              {title}
            </Typography>
            <ArrowOutwardIcon
              fontSize='small'
              color='secondary'
              sx={{
                transition: 'font-size 0.3s',
                cursor: 'pointer'
              }}
            // className={isHovered ? 'icon hovered' : 'icon'}
            />
          </Stack>
          <Typography
            variant="body1"
          // color={theme.palette.primary.light}
          >
            {description}
          </Typography>
          <Typography
            gutterBottom
            variant="caption"
            component="div"
            color='secondary'
          >
            {dateString}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

interface ImageContainerPropsInterface {
  children: React.ReactNode;
}

const ImageContainer: React.FC<ImageContainerPropsInterface> = ({ children }) => (
  <section
    style={{
      // height: '105vh',
      position: 'relative',
      overflow: 'hidden',
    }}
  >
    {children}
  </section>
);

const Image = () => (
  <img
    src={MCCArticleImage}
    alt="MccArticleImage"
    style={{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    }}
  />
);

const Text = () => {
  // useEffect(() => {
  //   console.log('overlayOpacity: ', overlayOpacity);
  // }, [overlayOpacity])
  // const { t } = useTranslation();
  // const [ref, inView] = useInView({
  //   triggerOnce: true,
  //   threshold: 0.2,
  // });

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 76,
        left: 16,
        color: '#fff',
        // backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: '8px 16px',
        width: '100%',
      }}
    // ref={ref}
    >
      <Typography variant='h1'>
        HELLO 124455
      </Typography>
    </div>
  );
};
