import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AppBar, useTheme, Typography, Box,
  IconButton, styled, Button, MenuItem,
  Toolbar, Divider, List, ListItem, ListItemButton,
  ListItemText, SwipeableDrawer, Menu, MenuProps, alpha,
  Stack, useMediaQuery,
} from '@mui/material';
import { Menu as MenuIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import ContactUsDialog, { ContactUsDialogInt } from '../../pages/LandingPage/Components/ContactUsDialog';

const NavigationButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  '&:hover': {
    color: theme.palette.secondary.main,
    backgroundColor: '#FFF',
  },
}));

const NavBarLeftMenuButton: React.FC<{setLeftDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>}> = ({setLeftDrawerOpen}) => {
  return (
    // <Box
    //   // bgcolor='skyblue'
    //   flex={1}
    //   // p={2}
    // >
    <>
      <IconButton
        color='primary'
        sx={{
          marginRight: '8px',
          // display: {
          //   xs: 'block',
          //   md: 'none', // Medium screen or bigger, display block
          // },
        }}
        onClick={() => { setLeftDrawerOpen(true) }}
      >
        <MenuIcon />
      </IconButton>
    </>
    // </Box>
  );
};

const NavBarTitle: React.FC = () => {
  return (
    <Box
      flex={3}
    >
      <div style={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
          }}>
        <Box
          component="img"
          justifyItems='center'
          sx={{
            // height: 45,
            width: '30%',
            // maxHeight: { xs: 50, md: 50 },
            minWidth: { xs: '200px', md: 50 },
            display: {
              // xs: 'none',
              // md: 'flex', // Small screen or bigger, display block
            },
          }}
          alt="logo"
          src="/JNC FULL.webp"
        />
      </div>
      {/* <Typography
        color='primary'
        variant='h4'
        fontSize='35px'
        fontWeight='bold'
        sx={{
          display: {
            xs: 'none',
            md: 'block', // Small screen or bigger, display block
          },
          textAlign: 'center',
        }}
      >
        JNC LOGISTICS (S) PTE LTD
      </Typography> */}
    </Box>
  );
};


const AppTopBar: React.FC = (props) => {
  const theme = useTheme();
  const { lang } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const [displayMenuButton] = useState<boolean>(window.innerWidth >= 600);
  const [leftDrawerOpen, setLeftDrawerOpen] = useState<boolean>(false);
  const [contactUsDialog, setContactUsDialog] = useState<ContactUsDialogInt>({ isOpen: false });
  const highlightItem = (url: string): boolean => {
    if (url === '') return pathname === '/';
    return pathname.startsWith(`/${lang}${url}`);
  };

  return (
    // <Box sx={{ display: 'flex' }}>
      <AppBar
        component="nav"
        sx={{ bgcolor: 'white' }}
      >
        {/* <Container maxWidth="xl"> */}
        <Toolbar disableGutters>
          <Stack
            direction='row'
            justifyItems='center'
            justifyContent={isMobileView ? 'center' : ''}
            width={isMobileView ? '100%' : '50%'}
            // width={isMobileView ? '100%' : ''}
            // textAlign={isMobileView ? 'center' : ''}
            // textAlign={{
            //   xs: 'center'
            // }}
          >
            <img
              src="/JNC LOGISTICS_LOGO_ORANGE.png"
              alt='JNCLOGO'
              style={{
                // width: '30%',
                height: '35px',
                // marginRight: '10px',
                // marginTop: '5px',
                marginLeft: '18px',
              }}
            />
            {/* <img
              src="/JNC LOGISTICS_TEXT.png"
              alt='JNCLOGO_TEXT'
              style={{
                height: '50px',
                // height: '30px',
                // marginRight: '10px',
                // marginLeft: '8px'
              }}
            /> */}
            <Typography
              variant='h5'
              pl={3}
              fontWeight='bold'
              fontSize={'small'}
              sx={{
                display: isMobileView ? 'none' : '',
              }}
            >
              LOGISTICS (S) PTE LTD
            </Typography>
          </Stack>
          {/* <img
            src="/JNC FULL.webp"
            alt='JNCLOGO'
            style={{
              // width: '30%',
              height: '30px',
              marginRight: '10px',
              marginLeft: '8px'
            }}
          /> */}
              <div style={{ height: '100%',marginLeft: 'auto' }}>
                {
                  displayMenuButton ? (
                    <>
                    <NavigationButton sx={{ color: highlightItem('') ? theme.palette.secondary.main : 'primary.main' }} onClick={() => navigate(`/${lang}`)}>
                      {t('menu.home')}
                    </NavigationButton>
                    <NavigationButton sx={{ color: highlightItem('/about') ? theme.palette.secondary.main : 'primary.main' }} onClick={() => navigate(`/${lang}/about`)}>
                      {t('menu.about')}
                    </NavigationButton>
                    <NavigationButton sx={{ color: highlightItem('/services') ? theme.palette.secondary.main : 'primary.main' }} onClick={() => navigate(`/${lang}/services`)}>
                      {t('menu.services')}
                    </NavigationButton>
                    {/* <NavigationButton sx={{ color: highlightItem('/schedule') ? theme.palette.secondary.main : 'primary.main' }} onClick={() => navigate(`/${lang}/schedule`)}>
                      {t('menu.schedule')}
                    </NavigationButton> */}
                    <NavigationButton sx={{ color: highlightItem('/news') ? theme.palette.secondary.main : 'primary.main' }} onClick={() => navigate(`/${lang}/news`)}>
                      {t('menu.news')}
                    </NavigationButton>
                    <NavigationButton sx={{ color: highlightItem('/contact') ? theme.palette.secondary.main : 'primary.main' }} onClick={() => setContactUsDialog({ isOpen: true })}>
                      {t('menu.contact')}
                    </NavigationButton>
                    <NavigationButton sx={{ color: 'primary.main' }}>
                      <LanguageSelectorButton />
                    </NavigationButton>
                    </>
                  ) : (
                    <NavBarLeftMenuButton
                      setLeftDrawerOpen={setLeftDrawerOpen}
                    />
                  )
                }
              </div>
              <LeftDrawer
                leftDrawerOpen={leftDrawerOpen}
                setLeftDrawerOpen={setLeftDrawerOpen}
                setContactUsDialog={setContactUsDialog}
              />
        </Toolbar>
        <ContactUsDialog
          contactUsDialog={contactUsDialog}
          setContactUsDialog={setContactUsDialog}
        />
        {/* </Container> */}
        {/* <Toolbar>
          <NavBarLeftMenuButton
            setLeftDrawerOpen={setLeftDrawerOpen}
          />
          <Box
            component="img"
            // justifyItems='center'
            sx={{
              // height: 45,
              width: '30%',
              // maxHeight: { xs: 50, md: 50 },
              minWidth: { xs: '200px', md: 50 },
              display: {
                // xs: 'none',
                // md: 'flex', // Small screen or bigger, display block
              },
            }}
            alt="logo"
            src="/JNC FULL.webp"
          />
          <Box sx={{ display: { xs: 'none', sm: 'block' }, width: '100%', textAlign: 'right' }}>
            <NavigationButton sx={{ color: highlightItem('/') ? theme.palette.secondary.main : 'primary.main' }} onClick={() => navigate('/')}>HOME</NavigationButton>
            <NavigationButton sx={{ color: highlightItem('/about') ? theme.palette.secondary.main : 'primary.main' }}>ABOUT US</NavigationButton>
            <NavigationButton sx={{ color: highlightItem('/services') ? theme.palette.secondary.main : 'primary.main' }}>OUR SERVICES</NavigationButton>
            <NavigationButton sx={{ color: highlightItem('/news') ? theme.palette.secondary.main : 'primary.main' }}>NEWS</NavigationButton>
            <NavigationButton sx={{ color: highlightItem('/contact') ? theme.palette.secondary.main : 'primary.main' }}>CONTACT US</NavigationButton>
          </Box>
        </Toolbar> */}
      </AppBar>
    // </Box>
  );
}; 

export default AppTopBar;

interface LeftDrawerInterface {
  leftDrawerOpen: boolean;
  setLeftDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContactUsDialog: React.Dispatch<React.SetStateAction<{isOpen: boolean;}>>;
}

const LeftDrawer: React.FC<LeftDrawerInterface> = ({
  leftDrawerOpen,
  setLeftDrawerOpen,
  setContactUsDialog,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { lang } = useParams();
  const { t } = useTranslation();

  const setDrawer = (res: boolean) => {
    setLeftDrawerOpen(res);
  };
  return (
    <SwipeableDrawer
      anchor='left'
      open={leftDrawerOpen}
      onClose={() => setDrawer(false)}
      onOpen={() => setDrawer(true)}
    >
      <Box
        sx={{ width: 250 }}
        role="presentation"
        // onClick={setDrawer(false)}
        // onKeyDown={setDrawer(false)}
        // component='div'
      >
        <div
          style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}
        >
          <Box
            component="img"
            justifyItems='center'
            sx={{
              // height: '20%',
              width: '200px',
              // maxHeight: { xs: 50, md: 50 },
              // maxWidth: { xs: 50, md: 50 },
              display: {
                xs: 'flex',
                md: 'none', // Small screen or bigger, display block
              },
            }}
            alt="logo"
            src="/JNC LOGISTICS_LOGO_ORANGE.png"
            p={2}
          />
        </div>
        <Typography
          color='primary'
          variant='h4'
          fontSize='15px'
          fontWeight='bold'
          textAlign='center'
        >
          JNC LOGISTICS (S) PTE LTD
        </Typography>
        <Divider
          sx={{
            // color: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main,
            // width: '10%',
            // minWidth: '30px',
            height: '0.8px',
          }}
        />
        <List>
          <ListItem key={'drawer-item-home'} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(`/${lang}`);
                setDrawer(false);
              }}
            >
              <ListItemText primary={t('menu.home')} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'drawer-item-aboutus'} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(`/${lang}/about`);
                setDrawer(false);
              }}
            >
              <ListItemText primary={t('menu.about')} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'drawer-item-services'} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(`/${lang}/services`);
                setDrawer(false);
              }}
            >
              <ListItemText primary={t('menu.services')} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'drawer-item-news'} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(`/${lang}/news`);
                setDrawer(false);
              }}
            >
              <ListItemText primary={t('menu.news')} />
            </ListItemButton>
          </ListItem>
          {/* <ListItem key={'drawer-item-schedule'} disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(`/${lang}/schedule`);
                setDrawer(false);
              }}
            >
              <ListItemText primary={t('menu.schedule')} />
            </ListItemButton>
          </ListItem> */}
          <ListItem key={'drawer-item-contact'} disablePadding>
            <ListItemButton
              onClick={() => setContactUsDialog({ isOpen: true })}
            >
              <ListItemText primary={t('menu.contact')} />
            </ListItemButton>
          </ListItem>
        </List>
        <div style={{ paddingLeft: theme.spacing(1) }}>
          <LanguageSelectorButton
            setLeftDrawerOpen={setLeftDrawerOpen}
          />
        </div>
      </Box>
    </SwipeableDrawer>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

interface LanguageSelectorButtonInterface {
  setLeftDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const LanguageSelectorButton: React.FC<LanguageSelectorButtonInterface> = ({
  setLeftDrawerOpen,
}) => {
  const theme = useTheme();
  const { lang } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNavigate = (selectedLang: string) => {
    if (lang !== selectedLang) {
      handleClose();
      if (setLeftDrawerOpen) setLeftDrawerOpen(false);
      navigate(`/${selectedLang}${pathname.slice(3)}`);
    } else {
      handleClose();
    }
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        component='div'
      >
        {lang === 'en' ? '中文' : 'English'}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleNavigate('cn')} disableRipple>
          中文
          {
            lang !== 'en' ? (
              <CheckCircleIcon
                sx={{
                  marginLeft: theme.spacing(1),
                  color: '#003366',
                }}
              />
            ) : null
          }
        </MenuItem>
        <MenuItem onClick={() => handleNavigate('en')} disableRipple>
          English
          {
            lang === 'en' ? (
              <CheckCircleIcon
                sx={{
                  marginLeft: theme.spacing(1),
                  color: '#003366',
                }}
              />
            ) : null
          }
        </MenuItem>
      </StyledMenu>
    </div>
  );
};
