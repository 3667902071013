import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      construction: {
        title: 'Under Construction',
        description: 'Soon, you will be able to submit and manage your shipments all from our JNC Logistics platform.',
        stayTuned: 'Stay tuned!',
      },
      login: 'LOGIN',
      signUp: 'SIGN UP',
      contactUs: {
        title: 'CONTACT US',
        subTitle: 'Send us a message!',
        copy: 'Click button to copy',
        form: {
          name: 'Name',
          email: 'Email',
          message: 'Message',
          cancel: 'CANCEL',
          submit: 'SUBMIT',
        },
        resDialog: {
          success: {
            title: 'Message sent',
            message: 'Your message have been sent. We will get back to you shortly!',
          },
          failure: {
            title: 'Something Went Wrong',
            message: 'Please try again or call us at +65 68879700.',
          },
        },
      },
      services: {
        airFreight: {
          title: 'Air Freight',
          shortDescription: 'JNC Air Freight caters to both local and overseas clients.',
          description: 'JNC air freight caters to both locals and overseas clients. We are able to help  our clients to source for the best rate from various carriers. This has made us a choice for local and oversea clients and thus we are able to handle a decent volume of direct cross trade as well as sea/air or air/sea cargo.',
        },
        coldChain: {
          title: 'Cold Chain',
          shortDescription: 'One stop service center, for all food chain.',
          description: "We offer a comprehensive solution for cold food product logistics, providing procurement, storage, inventory management, and delivery services through advanced technology. By utilizing various temperature-controlled compartments, we maintain the freshness and quality of poultry, beef, fruits, and vegetables. Our integrated warehouse management system ensures efficient storage, while our skilled professionals ensure careful doorstep delivery. We also offer customizable leasing options for food and beverage manufacturers. With our well-trained drivers and reliable reefer fleet, we establish a seamless cold chain for optimal perishable product quality. We are part of a global shipping network, connecting logistics to the world's freight system.",
        },
        eCommerce: {
          title: 'E-Commerce',
          shortDescription: 'JNC provides air & sea logistics for e-commerce.',
          description: 'JNC Logistics provides sea and air freight from warehouses in GuangZhou and YiWu, China to Singapore with door / warehouse delivery services. Our cargo is shipped via LCL / FCL consolidated services and will be dispatched and distributed in Singapore. We handle individual and personal cargo with the same door / warehouse delivery, this is best employed for larger TaoBao or E-Commerce purchases such as furniture, electronics, appliances or machinery.',
        },
        flexiBag: {
          title: 'Flexi-Bag',
          shortDescription: 'JNC offers flexi-bags/flexi-tanks as an alternative to ISO tank containers.',
          description: "Flexi-bags (also called flexi-tanks) offer an alternative to ISO tank containers for shipping oils, juices, wines, food-grade liquids and nonhazardous chemicals across the globe. Flexi bags are usually loaded into 20' food-grade containers for maritime transport. Size range from 14,000 to 24,000 litres.",
        },
        isoTank: {
          title: 'ISO tank',
          shortDescription: 'JNC owns more than 500 ISO tanks.',
          description: 'JNC has more than 500 of our own ISO Tanks. We offer one stop solution in transporting chemical liquids. Backed by the experience and dedicated team of staff, we strive in bringing customised solutions to our vast base of clients. In JNC ISO TANK service, we strongly believe to always provide the best service and support customers with the most efficient logistics in all sectors.',
        },
        lclConsolidation: {
          title: 'LCL Consolidation',
          shortDescription: 'LCL Consolidation services to more than 50 port destinations.',
          description: "LCL Consolidation to more than 50 ports destinations. Import & Export, International shipping for less than container loads, all JNC cargo is operated at our Container Freight Station within the Singapore Free Trade Zone. LCL service is a solution for  shipper as well as consignee who has insufficient cargo to form a container thus can depend on our service to ensure  cargo are ship smoothly and arrive punctually at destination. That is either at Container Freight Station or up to door. JNC is the sole LCL consolidator which can provide direct service to Shekou CFS, GuangDong Province, China - Qianhaiwan Free Trade Port Free Area. Cargo can be easily handled in Western Pearl River Delta to and from Hong Kong, South East Asia and the rest of the world. Handling of warehouse, tally, distribution, transit, import & export is included in our range of services. Our special rates in South China which include door delivery greatly enhance our client competitiveness and reduce delivery time. Transloading services is also provided in our Container Freight Station for Local as well as Cross Trade within the Free Trade Zone to ensure timely connection between vessels.",
        },
        projectCargos: {
          title: 'Project Cargos',
          shortDescription: 'JNC offers logistical support for large special odd sized cargo.',
          description: 'For oversized, voluminous and heavy cargo such as building material, heavy vehicle, pipes and etc we are able to provide special equipment such as flat rack, open top, low bed and heavy cranes to transport and deliver your cargo from door to door. For larger cargo transportation, JNC offers shipping for high-value, low-volume goods or outsized heavy lift cargo and other categories.',
        },
        socOperation: {
          title: 'SOC Operation',
          shortDescription: 'JNC Owns more than 1000 of our own containers.',
          description: 'JNC as a non-operating common carrier (Box operator), though we do not own any vessel, JNC has our own operator code from the Singapore Port Authority (PSA) to handle our own SOC like a vessel operator. For SOC sailing schedule, you may refer to undermentioned sailing schedule to place booking or contact us.',
        },
        shipAgencies: {
          title: 'Ship Agencies',
          shortDescription: 'Booking agent for aps.',
          description: 'JNC is a booking agent for APS. As a shipping agent, JNC ensures a berth for ships, arranges pilots and tugs, draws up customs and harbour documents and re-provisions vessels. JNC can also be relied upon to find a doctor to treat crew when needed. Our agency also has storage for bunkers, arranging repairs and conveying messages to and from the shipowner or ship manager as well as organising stevedores. collecting cargo and dealing with shippers and their agents. It is a full job that JNC is proud to do well.',
        },
        specialProjectCargo: {
          title: 'Special Project Cargo',
          shortDescription: 'We specialise in handling a large variety of special cargos.',
          description: 'JNC specializes in handling and storing dutiable items like alcohol and tobacco, offering licensed warehousing for international suppliers to store such goods without immediate import duty payment. We also provide military logistics services, including customized solutions for cross-trade shipments, as well as safe transportation of dangerous medical and chemical goods. With a dedicated project cargo team, they offer tailored solutions for logistics challenges and undername services to facilitate smooth import/export procedures for Indonesia shipments.',
        },
      },
      menu: {
        home: "HOME",
        about: "ABOUT",
        services: "OUR SERVICES",
        schedule: "SAILING SCHEDULE",
        news: "NEWS",
        contact: "CONTACT",
      },
      landingPage: {
        sectionOne: {
          lineOne: "WAREHOUSING ",
          lineTwo: "& LOGISTICS",
          lineThree: "SHIP4ME SERVICE",
        },
        sectionCoreService: {
          china: 'China',
          singapore: 'Singapore',
          malaysia: 'Malaysia',
          cambodia: 'Cambodia',
          batam: 'Batam',
          airFreight: 'Air Freight',
          seaFreight: 'Sea Freight',
          groundFreight: 'Ground Freight',
          doorToDoor: 'Door To Door',
          sg: {
            lineOne: "JNC Logistics provides comprehensive FCL/LCL shipping services between China (Guangzhou & Yiwu) and Singapore, offering a dependable daily shipping schedule.",
            lineTwo: "We are able to handle all import/ export and tax related documentations to ensure seamless customs clearance.",
          },
          my: {
            lineOne: "JNC Logistics provides comprehensive FCL/LCL shipping services between China (Guangzhou & Yiwu) and Malaysia, offering a dependable daily shipping schedule.",
            lineTwo: "We are able to handle all import/ export and tax related documentations to ensure seamless customs clearance.",
          },
          bm: {
            lineOne: "JNC Logistics provides comprehensive FCL/LCL shipping services between China (Guangzhou) and Batam Indonesia, offering a dependable daily shipping schedule.",
            lineTwo: "We are able to handle all import/ export and tax related documentations to ensure seamless customs clearance.",
          },
          mysg: {
            lineOne: "JNC Logistics provides daily land transport services between Singapore & Malaysia.",
            lineTwo: "We are able to handle all import/ export and tax related documentations to ensure seamless customs clearance.",
          },
          camsg: {
            lineOne: "JNC Logistics provides comprehensive FCL/LCL weekly shipping services between Singapore and Cambodia.",
            lineTwo: "We are able to handle all import/ export and tax related documentations to ensure seamless customs clearance.",
          },
        },
        sectionTwo: {
          lineOne: "ABOUT JNC LOGISTICS PTE LTD",
          lineTwo: "Reliable. Efficient. Worldwide",
          lineThree: "Logistics Solutions",
          lineFour: "JNC Logistics was established in 2017 as an e-commerce company, specialising in facilitating internet purchase from various parts of China. We operate various Container Freight Stations (CFS) around the world. In Foshan, we have 3 CFS actively receiving goods daily, with the exception of Sundays and public holidays. Each CFS is 50,000 sq ft, and our monthly cargo volume is more than 5,000 cubic metres, with continuous growth.",
        },
        sectionThree: {
          keyFeatures: {
            one: {
              title: "Ship To Or Out of China",
              description: "and South East Asia",
            },
            two: {
              title: "Double Custom Clearance",
              description: "resulting in faster clearance",
            },
            three: {
              title: "FIXED Established",
              description: "Sailing Schedule",
            },
            four: {
              title: "Managing >200,000 sqft",
              description: "of Container Freight Station",
            },
          },
          lineOne: "OUR SERVICES",
          lineTwo: "Established FCL/LCL network from all ports of China to South East Asia",
          lineThree: "BRINGING THE WORLD'S PRODUCTS TO CHINA",
          lineFour: "We are capable of rapidly transporting goods from all around the world to China. From raw materials to high-tech products, we are confident of ensuring smooth customs clearance. This is due to our wide logistics network and rich expertise in diverse cargo handling. Trust us as your reliable partner for seamless supply chain solutions and capitalize on opportunities in the world's factory!",
        },
        sectionFour: {
          lineOne: "Focus On What",
          lineTwo: "Really Matters",
          lineThree: "Let us streamline the logistics for you. Whether its a for an upcoming project, or for your day to day operations, our team's wealth of experience will be able to provide you with seamless logistical solutions to ensure business success.",
          lineFour: "CONTACT US",
        },
      },
      aboutUsPage: {
        title: "ABOUT US",
        sectionOne: {
          lineOne: "JNC LOGISTICS",
          lineTwo: "Established 2017"
        },
        sectionTwo: {
          lineOne: "JNC Logistics was established in 2017 as an e-commerce company, specialising in facilitating internet purchase from various parts of China. We operate various Container Freight Stations (CFS) around the world. In Foshan, we have 3 CFS actively receiving goods daily, with the exception of Sundays and public holidays. Each CFS is 50,000 sq ft, and our monthly cargo volume is more than 5,000 cubic metres, with continuous growth.",
          lineTwo: "In Singapore, JNC Logistics operates its own CFS within the Free Trade Zone under our parent company, JNC Line Pte Ltd. This enables timely transloading of goods for onward connections to the next available vessel. Moreover, having our own CFS allows our client’s goods to be stored without incurring GST (Goods & Services Tax) until they are sold in Singapore, at which GST becomes payable, providing a more efficient and cost effective solution.",
          lineThree: "In addition to facilitating e-commerce companies, JNC Logistics also manages substantial volumes of Full Container Load (FCL) containers and heavy equipment for various clients such as China Railways, China Communication & Construction Company and popular restaurant chains (e.g. Putien Restaurant). We handle the logistics of a wide range of products, from furniture, advanced bicycles made in China, Military, Medical & Chemical, among other types of cargo.",
          lineFour: "JNC Logistics will be expanding our e-commerce service to neighbouring countries, when opportunity arises.",
        },
        sectionThree: {
          lineOne: "The Team",
          lineTwo: "Making",
          lineThree: " Growth",
          lineFour: "Happen",
          teamOneTitle: "Commercial Customers Relation Team",
          teamOneDescription: "Our customer relations team is committed to securing your company's success, providing unwavering support 24/7. With extensive years of experience, our dedicated team is confident in navigating complex procedures and tasks, delivering exceptional service.",
          teamTwoTitle: "Warehouse Management Team",
          teamTwoDescription: "Our warehouse management team, backed by cutting-edge software and technology and adhering to robust inventory management procedures, efficiently handles substantial cargo volumes with precision and reliability.",
          teamThreeTitle: "Project Management Team",
          teamThreeDescription: "Serving you always",
        },
        sectionFour: {
          lineOne: "The Team",
        },
      },
      articles: {
        title: 'LATEST NEWS',
        viewAllNews: 'VIEW ALL NEWS',
        articles: 'ARTICLES',
        companyProject: 'Company Project',
        "mcc-qianhai": {
          title: 'Project MCC-QIANHAI 2.0',
          shortDescription: 'JNC Logistics Pte Ltd connects the world to the worlds largest factory in our new Multi-country Cargo Consolidation (MCC) center in QianHai. Situated an advantageous geographical location, we are able to rapidly deliver raw materials from all around the to major factories around China',
          content: "In 2018, JNC and FSI co-founded the Consolidation Alliance Network (CAN) to align with China Merchants Bonded Logistics' (CMBL’s) \"China import\" program. This initiative began by importing goods in FCL and LCL from ASEAN countries through Qianhai as gateways. Our operation model allows importers to benefit from ASEAN tax exemptions and qualify for the Regional Comprehensive Economic Partnership (RCEP) after it took effect in January 2022.\nOver the past five years, we have built an extensive network of CAN members that contribute to weekly consolidation services from all Asian countries to Qianhai, providing weekly sailings that connect major Asian economies with China. Long-haul shipments from worldwide countries can also be connected with Qianhai via weekly service from Singapore.\nBeing a country with huge demand for exports, and with decades of experience and technological assembled knowledge, China is able to produce high-quality products that meet and exceed world-recognized quality standards. In addition, China's proximity to other production bases in Asia makes it an ideal hub for international buyers.Qianhai MCC 2.0 serves as a functional Distribution Centre (DC) for 3PL / 4PL operations, including assembling, labelling, repacking, kitting, Merge in Transit (MIT), Vendor Managed Inventory (VMI), and more, whether it's Original Equipment Manufacturer (OEM) or Original Design Manufacturer (ODM). The finished products can be re-exported to foreign countries or sold in China's economy, with a population of 1.4 billion, a number no one can ignore. Currently there are more than 20 international buyers interested in or actively using our MCC-Qianhai 2.0 solutions,and the number is continuously increasing.",
        },
        "jnc-china-thailand": {
          title: "Gateway to Seamless China-Thailand Logistics",
          shortDescription: "Opening of Bangkok branch to provide unrivaled logistics services along the China-Thailand route",
          content: "In an era of global connectivity and thriving trade, the backbone of successful businesses lies in seamless logistics operations. We are thrilled to announce a new chapter in our journey as a leading logistics company, as we expand our horizons and open a dynamic branch in the heart of Bangkok, Thailand. This strategic move is a testament to our commitment to serving our clients' needs, enhancing efficiency, and fostering stronger partnerships. As part of this expansion, we are proud to introduce our Commercial Customers Relation Team, headed by a seasoned industry expert, poised to elevate your logistics experience to unparalleled heights.\nNew Branch in Bangkok:\nThe strategic decision to establish a branch in Bangkok reflects our dedication to providing world-class logistics solutions across international borders. With Bangkok as a thriving hub of commerce, our presence here will enable us to cater to a wider network of businesses seeking reliable and efficient shipping services. Whether you require Full Container Load (FCL) or Less than Container Load (LCL) services, our Bangkok branch is poised to deliver unparalleled expertise and support.\nIntroducing Our Commercial Customers Relation Team:\nTo further enhance our commitment to personalized service, we are proud to introduce our dedicated Commercial Customers Relation Team. Led by an experienced Head of Department, this team is specially designed to cater to the unique needs of our commercial clients. Our Customer Relations Team is more than just a support unit; they are your partners in success, ensuring that every aspect of your logistics journey is smooth, efficient, and tailored to your requirements.",
        },
        "jnc-evs": {
          title: "Powering the Future of Mobility Together",
          shortDescription: "Proudly partners with leading car manufacturers to revolutionize the  of electric batteries, vehicles, and OEM parts",
          content: "In a monumental leap towards sustainable transportation, we're thrilled to announce our groundbreaking partnership with industry-leading car manufacturers. This collaboration marks a significant stride in the evolution of logistics, with a focus on transporting electric car batteries, vehicles, and OEM parts. As a seasoned player in the field, our expertise extends beyond the ordinary, encompassing the handling of an extensive array of products, including dangerous goods and military equipment.\nBeyond the realm of conventional logistics, JNC Logistics is no strangers to navigating complexity. Our experience encompasses the seamless handling of diverse and challenging cargo, from hazardous materials to sensitive military products. With meticulous attention to regulations and safety protocols, we assure you of smooth customs declaration and documentation procedures. The result? A seamless transit, underpinned by a commitment to compliance and security.\nWe are experienced in handling a wide variety of products, and we are able to streamline customs processes and documentation to ensure seamless logistics of your products.",
        },
        "jnc-new-cfs": {
          title: "Expanding Horizons, Delivering Success",
          shortDescription: "We are excited to introduce our new Container Freight Station (CFS) in Foshan, covering an expansive area of 50,000 sqm.",
          content: "In an unprecedented leap forward, we are thrilled to reveal a monumental addition to our logistics prowess – a colossal new warehouse spanning over 200,000 square meters in Foshan, China. This awe-inspiring expansion serves as a testament to our unwavering commitment to excellence, enabling us to amplify our Full Container Load (FCL) and Less than Container Load (LCL) services to redefine the logistics landscape on a global scale.",
        },
      },
      footer: {
        quicklinks: 'Quicklinks'
      },
    }
  },
  cn: {
    translation: {
      construction: {
        title: '建设中',
        description: '很快，您将能够通过我们的 JNC Logistics 平台提交和管理您的货件。',
        stayTuned: '敬请关注!',
      },
      login: '登录',
      signUp: '报名',
      contactUs: {
        title: '联系我们',
        subTitle: '请给我们留言，我们会尽快回复您！',
        copy: '点击按钮进行复制',
        form: {
          name: '姓名',
          email: '电子邮件',
          message: '信息',
          cancel: '取消',
          submit: '提交',
        },
        resDialog: {
          success: {
            title: '消息已发送',
            message: '您的消息已发送。我们会尽快给您回复！',
          },
          failure: {
            title: '出了些问题',
            message: '请重试或致电 +65 68879700 联系我们。',
          },
        },
      },
      services: {
        airFreight: {
          title: '空运',
          shortDescription: 'JNC提供到中国以及从中国到世界各地的空运服务.',
          description: 'JNC 空运服务为本地和海外客户提供服务。我们能够帮助我们的客户从不同的运营商那里获得最优惠的价格。这使我们成为本地和海外客户的选择，因此我们能够处理大量的直接交叉贸易以及海运/空运或空运/海运货物。',
        },
        coldChain: {
          title: '新鲜食品/低温运输系统',
          shortDescription: '一站式服务中心，面向所有食品链。',
          description: '我们为冷食品物流提供全面的解决方案，通过先进的技术提供采购、存储、库存管理和配送服务。通过利用各种温控隔间，我们保持家禽、牛肉、水果和蔬菜的新鲜度和质量。我们的集成仓库管理系统确保高效存储，而我们熟练的专业人员则确保精心送货上门。我们还为食品和饮料制造商提供可定制的租赁选项。凭借训练有素的司机和可靠的冷藏车队，我们建立了无缝冷链，以实现最佳的易腐产品质量。我们是全球航运网络的一部分，将物流与世界货运系统连接起来。',
        },
        eCommerce: {
          title: '电子商务',
          shortDescription: 'JNC为电子商务提供空运和海运物流',
          description: 'JNC Logistics提供从中国广州和义乌仓库到新加坡的海运和空运门/仓派送服务。我们的货物通过拼箱/整箱拼箱服务运输，并将在新加坡发货和配送。我们通过同一门/仓库交货来处理个人和个人货物，这最适合大型淘宝或电子商务购买，例如家具、电子产品、电器或机械.',
        },
        flexiBag: {
          title: '柔性袋',
          shortDescription: 'JNC 提供柔性袋/柔性罐作为 ISO 罐式集装箱的替代品。',
          description: "柔性袋（也称为柔性罐）提供了 ISO 罐式集装箱的替代品，用于在全球范围内运输油、果汁、葡萄酒、食品级液体和非危险化学品。柔性袋通常装入 20' 食品级集装箱中进行海运。容量范围为 14,000 至 24,000 升。",
        },
        isoTank: {
          title: 'ISO罐',
          shortDescription: 'JNC 拥有 500 多辆 ISO 储罐。',
          description: 'JNC 拥有 500 多辆自己的 ISO 储罐。我们提供化学液体运输的一站式解决方案。在经验丰富和敬业的员工团队的支持下，我们努力为广大客户提供定制解决方案。在JNC ISO TANK服务中，我们坚信始终能够提供最好的服务，并在各个领域以最高效的物流为客户提供支持。',
        },
        lclConsolidation: {
          title: '拼箱拼箱',
          shortDescription: '为 50 多个港口目的地提供拼箱拼箱服务。',
          description: '拼箱拼箱至 50 多个港口目的地。进出口、小于集装箱装载的国际运输，所有 JNC 货物均在我们位于新加坡自由贸易区内的集装箱货运站运营。拼箱服务是为托运人和收货人没有足够的货物形成集装箱的解决方案，因此可以依靠我们的服务来确保货物顺利运输并准时到达目的地。要么在集装箱货运站，要么送到门口。 JNC是唯一一家可为中国广东省蛇口CFS-前海湾保税港保税区提供直达服务的拼箱集运商。珠江三角洲西部可轻松处理往返香港、东南亚及世界各地的货物。我们的服务范围包括仓储、理货、配送、转运、进出口的处理。我们在华南地区的特价包括送货上门，大大增强了我们的客户竞争力并缩短了交货时间。我们的集装箱货运站还为自贸区内的本地和交叉贸易提供转运服务，以确保船舶之间的及时连接。',
        },
        projectCargos: {
          title: '项目货物',
          shortDescription: 'JNC 为大型特殊奇数货物提供物流支持。',
          description: '对于建筑材料、重型车辆、管道等超大、体积和重型货物，我们能够提供平架、开顶、低床和重型起重机等专用设备，为您的货物进行门到门运输和交付。对于大型货物运输，JNC 提供高价值、小批量货物或超大型重件货物和其他类别的运输。',
        },
        socOperation: {
          title: 'SOC操作',
          shortDescription: 'JNC 拥有 1000 多个自有集装箱。',
          description: 'JNC 作为非运营性公共承运人（Box 运营商），虽然我们不拥有任何船舶，但 JNC 拥有新加坡港务局 (PSA) 颁发的自己的运营商代码，可以像船舶运营商一样处理我们自己的 SOC。对于SOC船期，您可以参考以下船期进行预订或联系我们。',
        },
        shipAgencies: {
          title: '船舶代理公司',
          shortDescription: 'APS订舱代理。',
          description: 'JNC 是 APS 的订舱代理。作为航运代理，JNC 确保船舶泊位、安排引航员和拖船、起草海关和港口文件以及重新供应船舶。 JNC 还可以在需要时找到医生为船员提供治疗。我们的机构还拥有燃油储存设施，负责安排维修、向船东或船舶经理传达信息以及组织装卸人员。收取货物并与托运人及其代理人打交道。 JNC 为能够出色完成这项工作而感到自豪。',
        },
        specialProjectCargo: {
          title: '特殊项目货物',
          shortDescription: '我们专门处理各种特殊货物。',
          description: 'JNC 专门处理和储存酒精和烟草等应税物品，为国际供应商提供许可仓储来储存此类物品，而无需立即支付进口税。我们还提供军事物流服务，包括跨贸易运输的定制解决方案，以及危险医疗和化学物品的安全运输。他们拥有专门的项目货运团队，为物流挑战和名义服务提供量身定制的解决方案，以促进印度尼西亚货物顺利进出口。',
        },
      },
      menu: {
        home: "主页",
        about: "公司",
        services: "公司服务",
        schedule: "航行时间表",
        news: "最新消息",
        contact: "联系我们",
      },
      landingPage: {
        sectionOne: {
          lineOne: "仓储",
          lineTwo: "到物流",
          lineThree: "为我运送服务",
        },
        sectionCoreService: {
          china: '中国',
          singapore: '新加坡',
          malaysia: '马来西亚',
          cambodia: '柬埔寨',
          batam: '印度尼西亚（巴淡岛)',
          airFreight: '空运',
          seaFreight: '海洋货运',
          groundFreight: '陆运',
          doorToDoor: '门到门',
          sg: {
            lineOne: "JNC Logistics 在中国（广州和义乌）和新加坡之间提供全面的整箱/拼箱运输服务，提供可靠的每日运输时间表。",
            lineTwo: "我们能够处理所有进出口和税务相关文件，以确保无缝清关。",
          },
          bm: {
            lineOne: "JNC Logistics提供中国（广州）和印度尼西亚巴淡岛之间全面的整箱/拼箱运输服务，提供可靠的每日运输时间表。",
            lineTwo: "我们能够处理所有进出口和税务相关文件，以确保无缝清关。",
          },
          my: {
            lineOne: "JNC Logistics 在中国（广州和义乌）和马来西亚之间提供全面的整箱/拼箱运输服务，提供可靠的每日运输时间表。",
            lineTwo: "我们能够处理所有进出口和税务相关文件，以确保无缝清关。",
          },
          mysg: {
            lineOne: "JNC Logistics 提供新加坡和马来西亚之间的日常陆路运输服务。",
            lineTwo: "我们能够处理所有进出口和税务相关文件，以确保无缝清关。",
          },
          camsg: {
            lineOne: "JNC Logistics 在新加坡和柬埔寨之间提供每周综合的整箱/拼箱运输服务。",
            lineTwo: "我们能够处理所有进出口和税务相关文件，以确保无缝清关。",
          },
        },
        sectionTwo: {
          lineOne: "关于 JNC 物流私人有限公司",
          lineTwo: "可靠的。高效的。全世界",
          lineThree: "物流解决方案",
          lineFour: "JNC Logistics成立于2017年，是一家电子商务公司，专门为中国各地的网上购物提供便利。我们在世界各地经营多个集装箱货运站（CFS）。在佛山，我们有3个CFS每天积极收货，周日和公共假期除外。每个CFS面积达50,000平方尺，每月货运量超过5,000立方米，并持续增长。"
        },
        sectionThree: {
          keyFeatures: {
            one: {
              title: "运往或运出中国",
              description: "和东南亚",
            },
            two: {
              title: "双清关",
              description: "清关更快",
            },
            three: {
              title: "固定既定",
              description: "航行时间表",
            },
            four: {
              title: "管理超过 200,000 平方英尺",
              description: "的集装箱货运站",
            },
          },
          lineOne: "我们的服务",
          lineTwo: "建立了中国各港口至东南亚的整箱/拼箱网络",
          lineThree: "将世界产品引入中国",
          lineFour: "我们有能力将货物从世界各地快速运输到中国。从原材料到高科技产品，我们都充满信心。这是由于我们广泛的物流网络和在各种货物处理方面丰富的专业知识。相信。相信。相信我们作为您可靠的无缝供应链解决方案合作伙伴，并利用世界工厂的机会！"
        },
        sectionFour: {
          lineOne: "专注于真正",
          lineTwo: "重要的事情",
          lineThree: "让我们为您简化物流。无论是即将开展的项目，还是您的日常运营，我们团队丰富的经验都将能够为您提供无缝的物流解决方案，以确保业务成功。",
          lineFour: "联系我们",
        },
      },
      aboutUsPage: {
        title: "关于我们",
        sectionOne: {
          lineOne: "JNC LOGISTICS",
          lineTwo: "2017年成立"
        },
        sectionTwo: {
          lineOne: "JNC Logistics成立于2017年，是一家电子商务公司，专门为中国各地的网上购物提供便利。我们在世界各地经营多个集装箱货运站（CFS）。在佛山，我们有3个CFS每天积极收货，周日和公共假期除外。每个CFS面积达50,000平方尺，每月货运量超过5,000立方米，并持续增长。",
          lineTwo: "在新加坡，JNC Logistics 在母公司 JNC Line Pte Ltd 旗下的自由贸易区内运营着自己的 CFS。这使得能够及时转运货物，以便继续连接到下一艘可用的船只。此外，拥有我们自己的 CFS 可以让我们客户的货物在不产生 GST（商品及服务税）的情况下进行存储，直到它们在新加坡出售时需要缴纳 GST，从而提供了更高效、更具成本效益的解决方案。",
          lineThree: "除了为电子商务公司提供便利外，JNC Logistics还为中国铁路、中国交通建设公司和受欢迎的连锁餐厅（例如莆田餐厅）等不同客户管理大量整箱（FCL）集装箱和重型设备。我们处理各种产品的物流，包括家具、中国制造的高级自行车、军事、医疗和化学品以及其他类型的货物。",
          lineFour: "当机会出现时，JNC Logistics 将把我们的电子商务服务扩展到周边国家。",
        },
        sectionThree: {
          lineOne: "团队",
          lineTwo: "让",
          lineThree: " 成长",
          lineFour: "发生",
          teamOneTitle: "商业客户关系团队",
          teamOneDescription: "我们的客户关系团队致力于确保您公司的成功，提供 24/7 坚定不移的支持。凭借多年的丰富经验，我们的专业团队有信心完成复杂的程序和任务，提供卓越的服务。",
          teamTwoTitle: "仓库管理团队",
          teamTwoDescription: "我们的仓库管理团队以尖端软件和技术为后盾，遵循强大的库存管理程序，能够精确、可靠地高效处理大量货物。",
          teamThreeTitle: "项目管理团队",
          teamThreeDescription: "永远为您服务",
        },
        sectionFour: {
          lineOne: "团队",
        },
      },
      articles: {
        title: '最新消息',
        articles: '公司文章',
        viewAllNews: '查看所有新闻',
        companyProject: '公司项目',
        "mcc-qianhai": {
          title: '中冶前海2.0项目',
          shortDescription: 'JNC Logistics Pte Ltd 将世界与位于前海的新多国货物集运 (MCC) 中心的全球最大工厂连接起来。地理位置优越，我们能够快速地将原材料从各地运送到中国各大工厂',
          content: "In 2018, JNC and FSI co-founded the Consolidation Alliance Network (CAN) to align with China Merchants Bonded Logistics' (CMBL’s) \"China import\" program. This initiative began by importing goods in FCL and LCL from ASEAN countries through Qianhai as gateways. Our operation model allows importers to benefit from ASEAN tax exemptions and qualify for the Regional Comprehensive Economic Partnership (RCEP) after it took effect in January 2022.\nOver the past five years, we have built an extensive network of CAN members that contribute to weekly consolidation services from all Asian countries to Qianhai, providing weekly sailings that connect major Asian economies with China. Long-haul shipments from worldwide countries can also be connected with Qianhai via weekly service from Singapore.\nBeing a country with huge demand for exports, and with decades of experience and technological assembled knowledge, China is able to produce high-quality products that meet and exceed world-recognized quality standards. In addition, China's proximity to other production bases in Asia makes it an ideal hub for international buyers.Qianhai MCC 2.0 serves as a functional Distribution Centre (DC) for 3PL / 4PL operations, including assembling, labelling, repacking, kitting, Merge in Transit (MIT), Vendor Managed Inventory (VMI), and more, whether it's Original Equipment Manufacturer (OEM) or Original Design Manufacturer (ODM). The finished products can be re-exported to foreign countries or sold in China's economy, with a population of 1.4 billion, a number no one can ignore. Currently there are more than 20 international buyers interested in or actively using our MCC-Qianhai 2.0 solutions,and the number is continuously increasing.",
        },
        "jnc-china-thailand": {
          title: "中泰无缝物流的门户",
          shortDescription: "曼谷分公司开业，为中泰航线提供无与伦比的物流服务",
          content: "在全球互联互通和贸易蓬勃发展的时代，成功企业的支柱在于无缝物流运营。我们很高兴地宣布，随着我们拓展视野并在泰国曼谷市中心开设充满活力的分支机构，我们作为领先的物流公司的旅程将翻开新的篇章。这一战略举措证明了我们致力于满足客户需求、提高效率和培养更牢固的合作伙伴关系。作为此次扩张的一部分，我们很自豪地介绍我们的商业客户关系团队，由经验丰富的行业专家领导，准备将您的物流体验提升到无与伦比的高度。\n曼谷新分公司：\n在曼谷建立分公司的战略决策体现了我们致力于提供世界一流的跨国物流解决方案。曼谷作为一个蓬勃发展的商业中心，我们在这里的存在将使我们能够满足寻求可靠和高效运输服务的更广泛的企业网络的需求。无论您需要整箱装载 (FCL) 还是拼箱装载 (LCL) 服务，我们的曼谷分公司都准备提供无与伦比的专业知识和支持。\n介绍我们的商业客户关系团队：\n为了进一步加强我们对个性化服务的承诺，我们很荣幸地介绍我们专门的商业客户关系团队。该团队由经验丰富的部门主管领导，专为满足商业客户的独特需求而设计。我们的客户关系团队不仅仅是一个支持部门；他们是您成功的合作伙伴，确保您物流旅程的各个方面顺利、高效，并根据您的要求量身定制。",
        },
        "jnc-evs": {
          title: "共同推动移动出行的未来",
          shortDescription: "自豪地与领先的汽车制造商合作，彻底改变电池、车辆和 OEM 零件的运输",
          content: "为了实现可持续交通的巨大飞跃，我们很高兴宣布与行业领先的汽车制造商建立突破性的合作伙伴关系。此次合作标志着物流发展的重大进步，重点是运输电动汽车电池、车辆和 OEM 零件。作为该领域经验丰富的参与者，我们的专业知识超出了普通人的范围，涵盖了各种产品的处理，包括危险品和军事设备。\n除了传统物流领域，JNC Logistics 对应对复杂性并不陌生。我们的经验包括无缝处理各种具有挑战性的货物，从危险材料到敏感的军用产品。我们严格遵守法规和安全协议，确保您顺利办理报关和文件手续。结果？无缝运输，以对合规性和安全性的承诺为基础。\n我们在处理各种产品方面拥有丰富的经验，并且能够简化海关流程和文件，以确保您的产品的无缝物流。",
        },
        "jnc-new-cfs": {
          title: "拓展视野，取得成功",
          shortDescription: "我们很高兴在佛山推出新的集装箱货运站 (CFS)，占地 50,000 平方米",
          content: "在一次前所未有的飞跃中，我们很高兴地向大家展示我们物流实力的巨大补充——位于中国佛山的一座占地超过 200,000 平方米的巨大新仓库。这一令人惊叹的扩张证明了我们对卓越的坚定承诺，使我们能够扩大我们的整箱装载（FCL）和拼箱装载（LCL）服务，以重新定义全球范围内的物流格局。",
        },
      },
      footer: {
        quicklinks: '快速链接'
      },
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
