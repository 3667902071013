import React, { useState } from 'react';
import {
  useTheme, Box, Grid, Typography,
  Stack,
} from '@mui/material';
import { Place, LocalPhone, Email } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const Footer: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { lang } = useParams();

  const navigate = useNavigate();
  return (
    <>
      {/* <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          minHeight: '300px'
        }}
      > */}
      <Grid
        container
        justifyContent='space-between'
        // alignItems='flex-start'
      >
        {/* <Grid
          item
          xs={12}
          md={4}
          sx={{
            backgroundColor: '#FFF',
            padding: theme.spacing(2),
          }}
        >
          <Box
            component="img"
            justifyItems='center'
            sx={{
              // height: 45,
              width: '100%',
              // maxHeight: { xs: 50, md: 50 },
              minWidth: { xs: '200px', md: 50 },
              display: {
                // xs: 'none',
                // md: 'flex', // Small screen or bigger, display block
              },
            }}
            alt="logo"
            src="/JNC FULL.webp"
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: theme.palette.primary.main,
            // textAlign: 'center',
            padding: theme.spacing(2),
          }}
        >
          <Typography variant='h5' color='secondary' fontWeight='bold' paddingBottom={theme.spacing(2)}>{t('contactUs.title')}</Typography>
          <Stack
            direction='row'
            // spacing={2}
            // justifyContent='space-between'
            // justifyContent='center'
          >
            <Place color='secondary' sx={{ paddingLeft: '5px' }} />
            <Typography sx={{ paddingLeft: '20px' }} color='#FFF'>200 Cantonment Road,</Typography>
          </Stack>
          <Typography sx={{ paddingLeft: '48px' }} color='#FFF'>Southpoint, #10-02</Typography>
          <Typography sx={{ paddingLeft: '48px', paddingBottom: '10px' }} color='#FFF'>Singapore 089763</Typography>
          <Stack
            direction='row'
            sx={{ padding: '10px 0' }}
          >
            <LocalPhone color='secondary' sx={{ paddingLeft: '5px' }} />
            <Typography sx={{ paddingLeft: '20px' }} color='#FFF'>+65 91145329 (Main)</Typography>
          </Stack>
          <Stack
            direction='row'
            sx={{ padding: '10px 0' }}
          >
            <Email color='secondary' sx={{ paddingLeft: '5px' }} />
            <Typography sx={{ paddingLeft: '20px' }} color='#FFF'>shulan@jnclogistics.com.sg</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: theme.palette.primary.main,
            // textAlign: 'center',
            padding: theme.spacing(2),
          }}
        >
          <Typography variant='h5' color='secondary' fontWeight='bold' paddingBottom={theme.spacing(2)}>{t('footer.quicklinks')}</Typography>

          <Typography
            color='#FFF'
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(`/${lang}`);
            }}
          >
            {t('menu.home')}
          </Typography>
          <Typography
            color='#FFF'
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(`/${lang}/about`);
            }}
          >
            {t('menu.about')}
          </Typography>
          <Typography
            color='#FFF'
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(`/${lang}/services`);
            }}
          >
            {t('menu.services')}
          </Typography>
          <Typography
            color='#FFF'
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(`/${lang}/news`);
            }}
          >
            {t('menu.news')}
          </Typography>
        </Grid>
      </Grid>
      {/* </Box> */}
      <Box
        sx={{
          backgroundColor: '#192F3E',
          textAlign: 'center',
        }}
      >
        <Typography variant='caption' color='#FFF'>
          Copyright &copy; 2023 JNC Logistics Pte Ltd - All Rights Reserved.
        </Typography>
      </Box>
  </>
  );
};

export default Footer;
