import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

// const useStyles = makeStyles((theme) => ({
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: '#fff',
//   },
// }));

const LoadingSpinner: React.FC = () => {
  return (
    <Backdrop sx={{ color: '#fff' }} open>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default LoadingSpinner;
