import React, { useEffect, useState } from 'react';
import {
  styled, useTheme, Container, Typography,
  Grid, Box, Card, CardContent, Stack,
  Divider, Slide, Paper,
} from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import HandshakeIcon from '@mui/icons-material/Handshake';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import InfoIcon from '@mui/icons-material/Info';

import Bagimg from '../../images/WarehouseAboutUS.jpg';
import SalesTeamImg from '../../images/SalesTeam.jpg';
import TeamImage from '../../images/MCCQIANHAI.jpeg'
import WarehouseStockImage from '../../images/WarehouseStockImage.jpg';
import CustomerRelationsTeam from '../../images/CustomerRelationsTeam.jpg';

const RootContainer = styled('div')({
  position: 'relative',
  height: '100vh',
  backgroundImage: `url(${Bagimg})`, // Replace with the path to your image
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
});

const DarkOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.60)', // Adjust the opacity and color as needed
});

const Content = styled('div')({
  position: 'absolute',
  bottom: '5%',
  paddingLeft: '3%',
  // right: '10%',
  // transform: 'translate(-50%, -50%)',
  textAlign: 'left',
  color: '#fff',
});

const AboutUsPage: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <>
      <RootContainer ref={ref}>
        <DarkOverlay></DarkOverlay>
        <Slide direction='up' in={inView} mountOnEnter unmountOnExit timeout={1500}>
        <Content>
          <>
            <Typography variant="h1" fontWeight='bold' display='inline' color='secondary'>JNC </Typography>
            <Typography variant="h1" fontWeight='bold' display='inline' color='#FFF'>LOGISTICS</Typography>
          </>
          <>
            <Typography variant="h5" fontWeight='bold' color='#FFF'>
              {t('aboutUsPage.sectionOne.lineTwo')}
            </Typography>
          </>
        </Content>
        </Slide>
      </RootContainer>
      <AboutCompanyDescription />
      <CompanyImagesV2 />
      {/* <CompanyImages /> */}
      <TeamSection />
      {/* <KeyPhaseSection />
      <TeamIntroduction /> */}
    </>
  );
};

export default AboutUsPage;

const CompanyImagesV2 = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Container
      maxWidth='xl'
      sx={{
        backgroundColor: '#192F3E',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      }}
    >
      <Paper
        variant='outlined'
        sx={{
          backgroundColor: '#192F3E',
          marginTop: theme.spacing(3)
        }}
      >
        <Grid
          container
          justifyContent='space-between'
          alignItems='flex-start'
          p={2}
          spacing={6}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                paddingTop: '15vh',
                // alignItems: 'center',
                // justifyContent: 'center',
              }}
            >
            <Typography variant='h3' fontWeight='bold' color='#FFF'>{t('aboutUsPage.sectionThree.lineOne')}</Typography>
            <Typography variant='h3' fontWeight='bold' color='#FFF' display='inline'>{t('aboutUsPage.sectionThree.lineTwo')}</Typography>
            <Typography variant='h3' fontWeight='bold' color='secondary' display='inline'> {t('aboutUsPage.sectionThree.lineThree')}</Typography>
            <Typography variant='h3' fontWeight='bold' color='secondary'>{t('aboutUsPage.sectionThree.lineFour')}</Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box
              width='100%'
              height='80vh'
              sx={{
                position: 'relative',
                transition: 'border 0.6s ease',
                '&:hover img': {
                  border: '4px solid orange', // Orange border on image hover
                },
              }}
            >
              <img
                src={CustomerRelationsTeam}
                alt="CustomerRelationsTeam"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)', // Translucent overlay color
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  textAlign: 'center',
                  padding: '20px',
                  opacity: 0, // Initially transparent
                  transition: 'opacity 0.6s ease', // Smooth opacity transition on hover
                  '&:hover': {
                    opacity: 1, // Fully visible on hover
                  },
                }}
              >
                <Typography variant='h5' fontWeight='bold' color='#FFF'>"{t('aboutUsPage.sectionThree.teamOneDescription')}"</Typography>
                {/* You can add more text content or other elements inside the Box */}
              </Box>
            </Box>
            <Stack
              direction='row'
              spacing={1}
              justifyContent={{
                xs: 'left',
                md: 'left',
              }}
              alignItems='center'
              divider={<Divider orientation="vertical" flexItem color='#FFF' />}
              paddingTop={theme.spacing(2)}
              // sx={{ width: '60%' }}
            >
              <HandshakeIcon sx={{color: '#FFF', fontSize: '60px'}} />
              <Typography variant='h5' fontWeight='bold' color='#FFF'>{t('aboutUsPage.sectionThree.teamOneTitle')}</Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box
              width='100%'
              height='80vh'
              sx={{
                position: 'relative',
                marginTop: {
                  md: '-40vh'
                },
                transition: 'border 0.6s ease',
                '&:hover img': {
                  border: '4px solid orange', // Orange border on image hover
                },
              }}
            >
              <img
                src={WarehouseStockImage}
                alt="teampic"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)', // Translucent overlay color
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  textAlign: 'center',
                  padding: '20px',
                  opacity: 0, // Initially transparent
                  transition: 'opacity 0.6s ease', // Smooth opacity transition on hover
                  '&:hover': {
                    opacity: 1, // Fully visible on hover
                  },
                }}
              >
                <Typography variant='h5' fontWeight='bold' color='#FFF'>"{t('aboutUsPage.sectionThree.teamTwoDescription')}"</Typography>
                {/* You can add more text content or other elements inside the Box */}
              </Box>
            </Box>
            <Stack
              direction='row'
              spacing={2}
              justifyContent={{
                xs: 'left',
                md: 'left',
              }}
              alignItems='center'
              divider={<Divider orientation="vertical" flexItem color='#FFF' />}
              paddingTop={theme.spacing(2)}
              // sx={{ width: '60%' }}
            >
              <WarehouseIcon sx={{color: '#FFF', fontSize: '60px'}} />
              <Typography variant='h5' fontWeight='bold' color='#FFF'>{t('aboutUsPage.sectionThree.teamTwoTitle')}</Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box
              width='100%'
              height='80vh'
              sx={{
                position: 'relative',
                transition: 'border 0.6s ease',
                '&:hover img': {
                  border: '4px solid orange', // Orange border on image hover
                },
                marginTop: {
                  // xs: '50px',
                  md: '10vh',
                },
              }}
            >
              <img
                src={CustomerRelationsTeam}
                alt="teampic"
                style={{
                  width: '100%',
                  height: '80vh',
                  // height: '100%',
                  objectFit: 'cover',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)', // Translucent overlay color
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  textAlign: 'center',
                  padding: '20px',
                  opacity: 0, // Initially transparent
                  transition: 'opacity 0.6s ease', // Smooth opacity transition on hover
                  '&:hover': {
                    opacity: 1, // Fully visible on hover
                  },
                }}
              >
                <Typography variant='h5' fontWeight='bold' color='#FFF'>{t('aboutUsPage.sectionThree.teamOneDescription')}</Typography>
                {/* You can add more text content or other elements inside the Box */}
              </Box>
            </Box>
            <Stack
              direction='row'
              spacing={2}
              justifyContent={{
                xs: 'left',
                md: 'left',
              }}
              alignItems='center'
              divider={<Divider orientation="vertical" flexItem color='#FFF' />}
              paddingTop={theme.spacing(2)}
            >
              <InfoIcon sx={{color: '#FFF', fontSize: '60px' }} />
              <Typography variant='h5' fontWeight='bold' color='#FFF'>{t('aboutUsPage.sectionThree.teamThreeTitle')}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

interface CustomImageContainerProps {
  image: any;
  textContent: string;
}

const CustomImageContainer: React.FC<CustomImageContainerProps> = ({
  image,
  textContent,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      width='100%'
      height='80vh'
      sx={{
        marginTop: {
          // xs: '50px',
          md: '10vh',
        },
        textAlign: 'right',
        alignContent: 'right',
      }}
    >
      <img
        src={image}
        alt="teampic"
        style={{
          width: '100%',
          height: '80vh',
          // height: '100%',
          objectFit: 'cover',
        }}
      />
    </Box>
  );
};

const AboutCompanyDescription: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Container
      maxWidth='xl'
      sx={{
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(6)
      }}
    >
      <Grid
        container
        // spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop={theme.spacing(3)}
      >
        {/* <Grid
          item
          xs={12}
          md={4}
          p={7}
          // paddingLeft='3%'
        >
          <Typography variant='h5' fontWeight='bold'>How We're</Typography>
          <Typography variant='h5' fontWeight='bold' color='secondary'>Different</Typography>
        </Grid> */}
        <Grid
          item
          xs={12}
          // md={8}
          md={12}
          // p={7}
        >
          <Typography variant='h5' fontWeight='bold' display='inline'>{t('aboutUsPage.title')}</Typography>
          <br />
          <br />
          <Typography variant='body1'>
            {t('aboutUsPage.sectionTwo.lineOne')}
          </Typography>
          <br />
          <Typography variant='body1'>
            {t('aboutUsPage.sectionTwo.lineTwo')}
          </Typography>
          <br />
          <Typography variant='body1'>
            {t('aboutUsPage.sectionTwo.lineThree')}
          </Typography>
          <br />
          <Typography variant='body1'>
            {t('aboutUsPage.sectionTwo.lineFour')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const CompanyImages = () => {
  const theme = useTheme();

  return (
    <div
      style={{ padding: theme.spacing(2),
      // backgroundColor: 'red'
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        // sx={{ backgroundColor: 'blue'}}
      >
        {
          [
            {
              title: 'Commercial Customers Relation Team',
              icon: <HandshakeIcon />,
              image: SalesTeamImg,
            },
            {
              title: 'Warehouse Management Team',
              icon: <WarehouseIcon />,
              image: SalesTeamImg,
            },
            {
              title: 'Project Management Team',
              icon: <InfoIcon />,
              image: SalesTeamImg,
            },
          ].map((item) => (
            <Grid item xs={12} md={4} sx={{ paddingBottom: theme.spacing(2) }}>
              <Box sx={{ textAlign: 'center' }}>
                <img
                  src={item.image}
                  alt={`Image2`}
                  style={{ width: '70%' }}
                />
                <Stack
                  direction='row'
                  spacing={1}
                  justifyContent='center'
                  alignItems='center'
                  divider={<Divider orientation="vertical" flexItem />}
                  // sx={{ width: '60%' }}
                >
                  {item.icon}
                  <Typography variant='body1' fontWeight='bold' paddingTop={theme.spacing(1)}>{item.title}</Typography>
                </Stack>
              </Box>
            </Grid>
          ))
        }
      </Grid>
    </div>
  );
};

const TeamSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div
      style={{
        padding: theme.spacing(2),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: 'center'
      // backgroundColor: 'red'
      }}
    >
      <Typography variant='h5' color='secondary' paddingBottom={theme.spacing(1)} fontWeight='bold'>{t('aboutUsPage.sectionFour.lineOne')}</Typography>
      <img
        src={TeamImage}
        alt={`Teamiamge`}
        style={{ width: '90%' }}
      />
    </div>
  );
};

// const KeyPhaseSection: React.FC = () => {
//   const theme = useTheme();

//   return (
//     <Box
//       sx={{
//         background: 'linear-gradient(to bottom, #FFF 50%, #003366 50%)',
//         // background: 'linear-gradient(to bottom, #003A64 50%, #FFF 50%)',
//         // height: '0vh',
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'center',
//         // alignItems: 'center',
//         paddingTop: theme.spacing(2),
//       }}
//     >
//       <Container maxWidth="lg">
//         <Card
//           variant='outlined'
//           sx={{
//             // backgroundColor: '#2C2D4A'
//             // backgroundColor: '#101E27'
//             backgroundColor: '#192F3E'
//           }}
//         >
//           <CardContent>
//             <h1>kjhg</h1>
//             <h1>kjhg</h1>
//             <h1>kjhg</h1>
//           </CardContent>
//         </Card>
//       </Container>
//     </Box>
//   );
// };

// const SectionContainer = styled(Grid)({
//   padding: '2rem',
//   backgroundColor: '#003366',
//   // backgroundColor: '#f0f0f0',
// });

// const ImageContainer = styled(Grid)({
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
// });

// const PortraitImage = styled('img')({
//   // height: '100%',
//   width: 'auto',
// });

// const TeamIntroduction = () => {
//   const theme = useTheme();
//   return (
//     <div style={{ padding: theme.spacing(3), backgroundColor: '#222335' }}>
//       <Grid container spacing={2}
//         direction="row"
//         justifyContent="space-between"
//         alignItems="center"
//       >
//         <Grid item xs={12} md={6}>
//           <Box
//             sx={{
//               paddingLeft: {
//                 md: theme.spacing(10),
//               }
//             }}
//           >
//             <Typography
//               variant='h5'
//               fontWeight='bold'
//               color='#f0f0f0'
//             >
//               The Team
//             </Typography>
//             <Typography
//               variant='h5'
//               fontWeight='bold'
//               color='#f0f0f0'
//               display='inline'
//             >
//               Making&nbsp;
//             </Typography>
//             <Typography
//               variant='h5'
//               fontWeight='bold'
//               display='inline'
//               color='secondary'
//             >
//               Growth Happen
//             </Typography>
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Box
//             sx={{
//               textAlign: 'center',
//               padding: theme.spacing(3),
//             }}
//           >
//             <img
//               src={SalesTeamImg}
//               alt={`Image1`}
//               style={{
//                 width: '70%',
//               }}
//             />
//             <Typography>The Team making</Typography>
//             <Typography>Growth happen</Typography>
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Box
//             sx={{
//               padding: theme.spacing(3),
//               paddingLeft: {
//                 md: theme.spacing(10),
//               },
//               marginTop: {
//                 md: '-280px',
//               },
//             }}
//           >
//             <img
//               src={SalesTeamImg}
//               alt={`Image2`}
//               style={{ width: '70%' }}
//             />
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <Box
//             sx={{
//               padding: theme.spacing(3),
//               textAlign: 'center',
//             }}
//           >
//             <img
//               src={SalesTeamImg}
//               alt={`Image3`}
//               style={{ width: '70%' }}
//             />
//           </Box>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };
