import React from 'react';
import {
  Button, Stack,
  Dialog, DialogTitle,DialogContent, DialogContentText, DialogActions, Typography,
} from '@mui/material';

export interface AlertDialogInterface {
  isOpen: boolean;
  icon: JSX.Element | undefined;
  title: string;
  message: string;
}

export interface AlertDialogProps {
  alertDialog: AlertDialogInterface;
  setAlertDialog: React.Dispatch<React.SetStateAction<AlertDialogInterface>>;
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  alertDialog,
  setAlertDialog,
}) => {
  const { isOpen, icon, title, message } = alertDialog;
  const handleClose = () => {
    setAlertDialog({
      isOpen: false,
      icon: undefined,
      title: '',
      message: '',
    });
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>
        <Stack direction='row' justifyItems='center'>
          {
            icon ? (
              icon
            ) : null
          }
          <Typography
            pl={icon ? 1 : ''}
          >
            {title}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
