import React from 'react';
import {
  useTheme, Box, Container, Typography,
  Card, CardContent, Grid, Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ContactUsDialogInt } from './Components/ContactUsDialog';
import WarehousePic from "../../images/WarehouseStockImage.jpg";

interface FocusOnWhatMattersSectionInterface {
  setContactUsDialog: React.Dispatch<React.SetStateAction<ContactUsDialogInt>>
}

const FocusOnWhatMattersSection: React.FC<FocusOnWhatMattersSectionInterface> = ({ setContactUsDialog }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        // background: 'linear-gradient(to bottom, #003366 50%, #FFF 50%)',
        background: 'linear-gradient(to bottom, #FFF 50%, #FFF 50%)',
        // background: 'linear-gradient(to bottom, #003A64 50%, #FFF 50%)',
        // height: '0vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        paddingTop: theme.spacing(2),
      }}
    >
      <Container maxWidth="lg">
        <Card
          variant='outlined'
          sx={{
            // backgroundColor: '#2C2D4A'
            // backgroundColor: '#101E27'
            backgroundColor: '#192F3E'
          }}
        >
          <CardContent>
            <Grid container justifyContent='space-between' alignItems='center' spacing={1}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant='h4'
                  sx={{
                    // marginBottom: theme.spacing(2),
                  }}
                  fontWeight='bold'
                  // fontStyle='italic'
                  color='secondary'
                >
                  {t('landingPage.sectionFour.lineOne')}
                </Typography>
                <Typography
                  variant='h4'
                  sx={{
                    // marginBottom: theme.spacing(3),
                    marginBottom: theme.spacing(1),
                  }}
                  fontWeight='bold'
                  // fontStyle='italic'
                  color='secondary'
                >
                  {t('landingPage.sectionFour.lineTwo')}
                </Typography>
                <Typography variant="body1" sx={{ color: theme.palette.primary.light }}>
                  {t('landingPage.sectionFour.lineThree')}
                </Typography>
                <Button
                  onClick={() => {
                    setContactUsDialog({ isOpen: true });
                  }}
                  variant='outlined'
                  size='large'
                  sx={{
                    // marginTop: theme.spacing(3),
                    margin: theme.spacing(1),
                    marginTop: {
                      xs: theme.spacing(2),
                      md: theme.spacing(3),
                    },
                    marginLeft: 0,
                    color: '#FFF',
                    borderColor: '#FFF',
                    '&:hover': {
                      backgroundColor: '#FFF',
                      borderColor: '#FFF',
                      boxShadow: 'none',
                      color: theme.palette.primary.main,
                    },
                    // '&:active': {
                    //   boxShadow: 'none',
                    //   backgroundColor: '#0062cc',
                    //   borderColor: '#005cbf',
                    // },
                    // '&:focus': {
                    //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                    // },
                  }}
                >
                  {t('landingPage.sectionFour.lineFour')}
                </Button>
              </Grid>
              <Grid
                item xs={12} md={8}
                sx={{ paddingTop: theme.spacing(2) }}
              >
                <img
                  src={WarehousePic}
                  alt='WarehousePic'
                  style={{
                    width: '100%',
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default FocusOnWhatMattersSection;
