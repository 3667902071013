import React, { useEffect, useState } from 'react';
import {
  useTheme, Container, Typography, Divider,
  Box, Grid, Stack, FormControl, MenuItem,
  SelectChangeEvent, Select, Button, Paper,
  ListSubheader,
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  LocalizationProvider, DatePicker,
} from '@mui/x-date-pickers';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EastIcon from '@mui/icons-material/East';

const SailingSchedule = () => {
  const theme = useTheme();

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <Container maxWidth='xl' sx={{ paddingTop: '64px', minHeight: '80vh' }}>
      <Box paddingTop={theme.spacing(4)}>
        <Typography variant='h5' fontWeight='bold'>Sailing Schedule</Typography>
        <Divider
          sx={{
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.main,
            width: '100%',
            height: '3px',
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(1),
          }}
        />
      </Box>
      {/* <Grid container>
        <Grid item p={1} sx={{ backgroundColor: 'green'}}>
          <Button variant='contained'>View By Country To Country</Button>
        </Grid>
        <Grid item p={1} pl={0} sx={{ backgroundColor: 'green'}}>
        <Button variant='contained'>View By Month</Button>
        </Grid>
      </Grid> */}
      <CountryToCountry />
    </Container>
  );
};

export default SailingSchedule;

const CountryToCountry: React.FC = () => {
  const theme = useTheme();
  return (
    <>
      <Paper
        variant='outlined'
        sx={{
          padding: theme.spacing(1),
          // backgroundColor: theme.palette.primary.light,
        }}
      >
        <CountryToCountrySearchFilter />
      </Paper>
      <Box>
        <Paper
          variant='outlined'
          sx={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(4),
            padding: theme.spacing(1),
            backgroundColor: theme.palette.primary.light,
          }}
        >
          <Stack
          // direction={{ xs: 'column', sm: 'row' }}
          direction='row'
          gap={1}
          alignItems='center'
          >
            <Typography variant='h6' fontWeight='bold'>Search Result: </Typography>
            <Typography variant='h6' fontWeight='bold'>SINGAPORE</Typography>
            <EastIcon color='secondary' />
            <Typography variant='h6' fontWeight='bold'>JAKARTA, INDONESIA</Typography>
          </Stack>
          <CountryToCountryItem />
          <CountryToCountryItem />
          <CountryToCountryItem />
        </Paper>
        {/* <Paper
          variant='outlined'
          sx={{
            // marginTop: theme.spacing(1),
            padding: theme.spacing(1),
            backgroundColor: theme.palette.primary.light,
          }}
        >
          Result
        </Paper> */}
      </Box>
    </>
  );
};

interface CountryToCountryHeaderProps {
  direction: 'arrival' | 'departure';
  country: string;
  date: string;
}

const CountryToCountryHeader: React.FC<CountryToCountryHeaderProps> = ({
  direction,
  country,
  date,
}) => {
  return (
    <Stack
      direction='column'
      // gap={1}
      // alignItems='center'
    >
      <Typography variant='body1' fontWeight='bold' color='#FFF'>{direction === 'arrival' ? 'ETA' : 'ETD'}: {date}</Typography>
      <Typography variant='caption' color='#FFF'>{country}</Typography>
    </Stack>
    // <>
    //   <div>
    //   <Typography variant='body1' fontWeight='bold' color='#FFF'>ETD: 4 AUG 2023</Typography>
    //   </div>
    //   <div>
    //   <Typography variant='caption' color='#FFF'>SINGAPORE</Typography>
    //   </div>
    // </>
  );
};

interface SequenceItemProps {
  direction: 'arrival' | 'departure';
  date: string;
  country: string;
  SeqIcon?: any;
}

const SequenceItem: React.FC<SequenceItemProps> = ({
  direction,
  date,
  country,
  SeqIcon,
}) => {
  const theme = useTheme();
  return (
    <Stack direction='column'>
      <Stack
        direction='row'
        gap={1}
        alignItems='center'
      >
        {
          SeqIcon ? <SeqIcon color='primary' /> : (
            <Divider
              orientation='vertical'
              sx={{
                // display: {
                //   md: 'none',
                // },
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.secondary.main,
                height: '30px',
                width: '3px',
                marginLeft: '10px',
              }}
            />
          )
        }
        <Typography
          variant='caption'
          paddingLeft={SeqIcon ? '' : '11px'}
        >
          {direction === 'arrival' ? 'ETA' : 'ETD'} {country}
        </Typography>
      </Stack>
      <Typography
        variant='body1'
        fontWeight='bold'
        paddingLeft={'33px'}
      >
        {date}
      </Typography>
    </Stack>
  );
};

const CountryToCountryItem: React.FC = () => {
  const theme = useTheme();

  return (
    <Paper
      variant='outlined'
      sx={{
        marginTop: theme.spacing(1),
        borderRadius: '10px',
      }}
    >
      <Box
        sx={{
          padding: theme.spacing(1),
          backgroundColor: theme.palette.primary.main,
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          gap={1}
          alignItems='center'
        >
          <DirectionsBoatIcon color='secondary' fontSize='large' />
          <CountryToCountryHeader
            direction='departure'
            country='SINGAPORE'
            date='4 AUG 2023'
          />
          <EastIcon color='secondary' />
          <CountryToCountryHeader
            direction='arrival'
            country='JAKARTA, INDONESIA (KOJA)'
            date='7 AUG 2023'
          />
        </Stack>
      </Box>
      <Box
        sx={{
          padding: theme.spacing(1),
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='caption'>VESSEL NAME</Typography>
            <Typography variant='body1' fontWeight='bold'>GANTA BUM</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='caption'>VOYAGE NUMBER</Typography>
            <Typography variant='body1' fontWeight='bold'>027S</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Typography variant='caption'>DG CARGO</Typography>
            <Stack
              direction='row'
              gap={1}
              alignItems='center'
            >
              <Typography variant='body1' fontWeight='bold'>Accepted</Typography>
              <CheckCircleIcon fontSize='small' color='success' />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <VesselSequence />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                textAlign: 'right',
                alignItems: 'center',
                display: "flex",
                // margin: 'auto',
              }}
            >
              <Button
                variant='contained'
                color='secondary'
                sx={{
                  color: '#FFF',
                  margin: 'auto',
                }}
              >
                BOOK VESSEL
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const VesselSequence: React.FC = () => {
  const theme = useTheme();
  return (
    <Stack
      direction='column'
      sx={{
        marginTop: theme.spacing(2)
      }}
    >
      <SequenceItem
        direction='arrival'
        country='SINGAPORE'
        date='4 AUG 2023'
        SeqIcon={DirectionsBoatIcon}
      />
      <SequenceItem
        direction='departure'
        country='SINGAPORE'
        date='5 AUG 2023'
        // SeqIcon={DirectionsBoatIcon}
      />
      <SequenceItem
        direction='arrival'
        country='JAKARTA, INDONESIA (KOJA)'
        date='7 AUG 2023'
        SeqIcon={LocationOnIcon}
      />
    </Stack>
  );
};

const CountryToCountrySearchFilter: React.FC = () => {
  const theme = useTheme();

  interface FormValuesInterface {
    origin: string;
    destination: string;
  }

  const [filter, setFiler] = useState<FormValuesInterface>({
    origin: 'SINGAPORE',
    destination: 'JAKARTA',
  });

  const handleChange = (event: SelectChangeEvent, field: string) => {
    setFiler((prevState: FormValuesInterface) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={3}>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'
          // sx={{ backgroundColor: 'red' }}
        >
          <LocationOnIcon
            fontSize='medium'
            color='primary'
          />
          <Typography variant='body1' fontWeight='bold' display='inline'>Origin</Typography>
          <Typography variant='body1' fontWeight='bold' display='inline' color='red'>*</Typography>
        </Stack>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'
          // sx={{ backgroundColor: 'red' }}
        >
          <Divider
            orientation='vertical'
            sx={{
              display: {
                md: 'none',
              },
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.secondary.main,
              height: '35px',
              width: '3px',
              marginLeft: '10px',
            }}
          />
          <FormControl sx={{ width: '100%' }}>
            <Select
              value={filter.origin}
              onChange={(event) => handleChange(event, 'origin')}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                marginTop: theme.spacing(1),
                marginLeft: {
                  xs: theme.spacing(1),
                  md: 0,
                },
              }}
            >
              {/* <MenuItem value="">
                <em>None</em>
              </MenuItem> */}
              <MenuItem value='SINGAPORE'>SINGAPORE</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'
          // sx={{ backgroundColor: 'red' }}
        >
          <LocationOnIcon
            fontSize='medium'
            color='primary'
            sx={{
              display: {
                md: 'none'
              }
            }}
          />
          <Typography variant='body1' fontWeight='bold' display='inline'>Destination</Typography>
          <Typography variant='body1' fontWeight='bold' display='inline' color='red'>*</Typography>
        </Stack>
        <FormControl sx={{ width: '100%' }}>
          <Select
            value={filter.destination}
            onChange={(event) => handleChange(event, 'destination')}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{
              marginTop: theme.spacing(1),
              marginLeft: {
                xs: '29px',
                md: 0,
              }
            }}
          >
            {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
            <ListSubheader>
              INDONESIA
            </ListSubheader>
            <MenuItem value='JAKARTA'>JAKARTA</MenuItem>
            <MenuItem value='SURABAYA'>SURABAYA</MenuItem>
            <ListSubheader>
              VIETNAM
            </ListSubheader>
            <MenuItem value='HAIPONG'>HAIPONG</MenuItem>
            <MenuItem value='CATLAI'>CATLAI</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={2}>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'
          // sx={{ backgroundColor: 'red' }}
        >
          <CalendarMonthIcon
            fontSize='medium'
            color='primary'
            sx={{
              display: {
                // md: 'none'
              }
            }}
          />
          <Typography variant='body1' fontWeight='bold'>Departs from</Typography>
        </Stack>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'
          // sx={{ backgroundColor: 'red' }}
        >
          <Divider
            orientation='vertical'
            sx={{
              display: {
                md: 'none',
              },
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.secondary.main,
              height: '35px',
              width: '3px',
              marginLeft: '10px',
            }}
          />
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
          >
              <DatePicker
                label="Depart From"
                sx={{
                  width: '100%',
                  marginTop: theme.spacing(1),
                  marginLeft: {
                    xs: theme.spacing(1),
                    md: 0,
                  },
                }}
              />
          </LocalizationProvider>
        </Stack>
      </Grid>
      <Grid item xs={12} md={2}>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'
        >
          <CalendarMonthIcon
            fontSize='medium'
            color='primary'
            sx={{
              display: {
                md: 'none'
              }
            }}
          />
          <Typography variant='body1' fontWeight='bold'>Arrives Before</Typography>
        </Stack>
        <Stack
          direction='row'
          gap={1}
          alignItems='center'
          // sx={{ backgroundColor: 'red' }}
        >
          <Divider
            orientation='vertical'
            sx={{
              display: {
                md: 'none',
              },
              color: 'white',
              backgroundColor: 'white',
              height: '35px',
              width: '3px',
              marginLeft: '10px',
            }}
          />
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
          >
              <DatePicker
                label="Depart From"
                sx={{
                  width: '100%',
                  marginTop: theme.spacing(1),
                  marginLeft: {
                    xs: theme.spacing(1),
                    md: 0,
                  },
                }}
              />
          </LocalizationProvider>
        </Stack>
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          variant='contained'
          color='secondary'
          startIcon={<SearchIcon />}
          // size='small'
          fullWidth
          sx={{
            color: 'white',
            marginTop: {
              xs: theme.spacing(1),
              md: '36px',
            },
            marginBottom: theme.spacing(1),
          }}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};
