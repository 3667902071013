import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  useTheme, Container, Typography, Paper,
  Button, Stack,
} from '@mui/material';

import articles, { ArticleInterface } from './articles';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import TeamPicture from '../../images/MCCQIANHAI.jpeg';
import { useTranslation } from 'react-i18next';

function MultilineTranslation(content: string) {
  const items = content.split('\n')
  return (
    <>
      {items.map(item => <Typography variant='body1' mb={2}>{item}</Typography>)}
    </>
  )
}

const NewsDetailPage: React.FC = () => {
  const { id, lang } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const title = t(`articles.${id}.title`);
  const shortDescription = t(`articles.${id}.shortDescription`);
  const content = t(`articles.${id}.content`);
  const { dateString } = articles.find((article) => article.id === id) as ArticleInterface;

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  return (
    <Container
      maxWidth='md'
      sx={{
        paddingTop: '80px',
      }}
    >
      <Paper
        sx={{
          padding: theme.spacing(3),
          margin: theme.spacing(2),
        }}
      >
        <Stack
          direction='row'
          // spacing={2}
          justifyContent='space-between'
          pb={2}
        >
          <Button
            variant='text'
            onClick={() => {
              navigate(`/${lang}/news`)
            }}
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: '#FFF',
            }}
          >
            Back To News
          </Button>
          <Typography
            gutterBottom
            variant="caption"
            component="div"
            color='secondary'
          >
            {dateString}
          </Typography>
        </Stack>
        <Typography variant='h4' fontWeight='bold' pb={2}>{title}</Typography>
        <div
          style={{
            width: `100%`,
            height: `50vh`,
            // overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <img
            src={TeamPicture}
            alt="teampic"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </div>
        <Typography variant='caption' sx={{ paddingBottom: theme.spacing(5) }}>
          {shortDescription}
        </Typography>
        <div style={{ marginTop: theme.spacing(2) }}>
          {MultilineTranslation(content)}
        </div>
        {/* <Swiper
          slidesPerView={1}
          // spaceBetween={10}
          centeredSlides={true}
        >
          <SwiperSlide>
            <img
              src={TeamPicture}
              alt={'NewsImage'}
            />
          </SwiperSlide>
        </Swiper> */}
      </Paper>
    </Container>
  );
};

export default NewsDetailPage;


