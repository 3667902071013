// import MCCQIANHAI from '../../images/MCCQIANHAI.jpeg';
import BangkokPicture from '../../images/bangkok_city.jpeg';
import MccArticleImage from '../../images/MccArticleImage.png';
import WarehouseActual from '../../images/WarehouseActual.jpeg';
import EVPicture from '../../images/EVPicture.jpg';
// import TeamPicture from '../../images/TeamPicture.png';

export interface ArticleInterface {
  id: string;
  dateString: string;
  title: string;
  description: string;
  image: string;
}

const NewsArticles = [
  {
    id: 'mcc-qianhai',
    dateString: '07/07/2023',
    title: 'Project MCC-QIANHAI 2.0',
    description: 'JNC Logistics Pte Ltd connects the world to the worlds largest factory in our new Multi-country Cargo Consolidation (MCC) center in QianHai. Situated an advantageous geographical location, we are able to rapidly deliver raw materials from all around the to majhor factories around China.',
    image: MccArticleImage,
  },
  {
    id: 'jnc-china-thailand',
    dateString: '07/07/2023',
    title: 'Gateway to Seamless China-Thailand Logistics',
    description: 'Opening of Bangkok branch to provide unrivaled logistics services along the China-Thailand route',
    image: BangkokPicture,
  },
  {
    id: 'jnc-evs',
    dateString: '07/07/2023',
    title: 'Powering the Future of Mobility Together',
    description: 'Proudly partners with leading car manufacturers to revolutionize the transportation of electric batteries, vehicles, and OEM parts',
    image: EVPicture,
  },
  {
    id: 'jnc-new-cfs',
    dateString: '07/07/2023',
    title: 'Expanding Horizons, Delivering Success',
    description: 'We are excited to introduce our new Container Freight Station (CFS) in Foshan, covering an expansive area of 50,000 sqm.',
    image: WarehouseActual,
  },
];

export default NewsArticles;
